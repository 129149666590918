import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-traductions',
  templateUrl: './traductions.component.html',
  styleUrls: ['./traductions.component.scss']
})
export class TraductionsComponent implements OnInit {

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  isLoading: boolean = false;

  langues: any = [];

  allTrads: any = [];
  allCouples: any = [];

  newTradTriggered: boolean = false;
  newTradName: string;
  newTradContent: any = [];

  editModal: boolean = false;
  tradEdit: any = 0;
  editTradContent: string;

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getLangElements().subscribe(
      data => {
        this.allTrads = data.data;
      },
      err => {
        console.log(err);
      }
    );
    this.apiService.getLangs().subscribe(
      data => {
        this.langues = data.data;
      },
      err => {
        console.log(err);
      }
    );
    this.loadElements();
  }

  loadElements() {
    this.allCouples.length = 0;

    let listFR = this.apiService.getLangElements({lang: 'fr_FR'});
    let listEN = this.apiService.getLangElements({lang: 'en_GB'});
    let listDE = this.apiService.getLangElements({lang: 'de_DE'});
    let all = this.apiService.getLangElements({});

    forkJoin([listFR, listEN, listDE, all]).subscribe(results => {
      
      results[0].data.forEach(elFR => {
        let enFriend = results[1].data.filter(elEN => {
          return elEN.name == elFR.name
        });
        let deFriend = results[2].data.filter(elDE => {
          return elDE.name == elFR.name
        });
        this.allCouples.push([elFR, enFriend[0], deFriend[0]]);
      });

      this.allTrads = results[3].data;

      this.isLoading = false;
    });
  }

  onAddTrad() {
    this.newTradTriggered = true;
  }

  closeNewTrad() {
    this.newTradTriggered = false;
  }

  newTrad() {
    if (!this.newTradContent.length) return;
    this.isLoading = true;
    this.langues.forEach((lang, i) => {
      this.apiService.addLangElement(this.newTradName, lang.code_lang, this.newTradContent[i]).subscribe(
        data => {
          if (i + 1 === this.newTradContent.length) {
            this.newTradTriggered = false;
            this.newTradName = '';
            this.newTradContent = [];
            this.loadElements();
            this.toastr.success('Traduction ajoutée');
          }
        },
        err => {
          console.log(err);
        }
      );
    });

  }

  onEdit(id) {
    this.editModal = true;
    this.tradEdit = this.allTrads.filter(obj => {
      return obj.id == id
    });
    this.editTradContent = this.tradEdit[0].content;
  }

  closeEditModal() {
    this.editModal = false;
    this.tradEdit = 0;
  }

  editTrad(id) {
    this.isLoading = true;
    this.apiService.addLangElement(this.tradEdit[0].name, this.tradEdit[0].lang, this.editTradContent, {lang_element_id: String(id)}).subscribe(
      data => {
        this.tradEdit = 0;
        this.loadElements();
        this.editModal = false;
        this.toastr.success('Traduction modifiée');
      },
      err => {
        console.log(err);
      }
    );
  }

}
