import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/fr';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Router } from '@angular/router';
// import * as L from 'Leaflet';
import * as L from 'leaflet';

@Component({
  selector: 'app-circuit-exits',
  templateUrl: './circuit-exits.component.html',
  styleUrls: ['./circuit-exits.component.scss']
})
export class CircuitExitsComponent implements OnInit {

  exits: any;
  filteredExits: any;
  isLoading: boolean = false;
  curPage: number = 1;
  itemsPerPage: number = 10;
  numberOfItems: number = 0;
  numberOfPages: number = 0;

  constructor(private apiService: ApiService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getExits();
  }

  getExits() {
    this.apiService.getExits().subscribe((res: any) => {
      if (res.success) {
        this.exits = res.data;
        this.exits.forEach((exit: any) => {
          exit.reasons = JSON.parse(exit.reasons);
        });
        this.exits.reverse()

        // add items in this.exits to have a round number of pages
        while(this.exits.length % this.itemsPerPage !== 0) {
          let copy = JSON.parse(JSON.stringify(this.exits[0]));

          // empty every key of copy
          for (let key in copy) {
            copy[key] = '';
          }
          this.exits.push(copy);
        }

        // calculate the number of items in this.exits that are not empty
        this.numberOfItems = this.exits.filter((exit: any) => exit.id).length;

        this.numberOfPages = Math.ceil(this.numberOfItems / this.itemsPerPage);

        this.filteredExits = Object.assign([], this.exits);
        this.filteredExits = this.exits.slice(0, this.itemsPerPage);
      }
    });
  }

  onSearchChange(searchValue: string): void {
    this.filteredExits = this.exits.filter((exit: any) => {
      return exit.trajet.title.toLowerCase().includes(searchValue.toLowerCase());
    })
  }

  previousPage() {
    if (this.curPage > 1) {
      this.curPage--;
      this.filteredExits = this.exits.slice((this.curPage - 1) * this.itemsPerPage, this.curPage * this.itemsPerPage);
    }
  }

  nextPage() {
    if (this.curPage < this.exits.length / this.itemsPerPage) {
      this.curPage++;
      this.filteredExits = this.exits.slice((this.curPage - 1) * this.itemsPerPage, this.curPage * this.itemsPerPage);
    }
  }
}
