import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { CommonService } from '../../../services/common.service';
import { Observable, Subscription } from 'rxjs';
import { SortablejsOptions } from 'ngx-sortablejs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/fr';

@Component({
  selector: 'app-step-one-publication',
  templateUrl: './step-one-publication.component.html',
  styleUrls: ['./step-one-publication.component.scss']
})
export class StepOnePublicationComponent implements OnInit {

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '7';

  isLoading: boolean = false;
  modalNewPoint: boolean = false;
  modalNewVariation: boolean = false;
  modalExplanations: boolean = false;

  pageTitle: string;
  title: string;
  description: string;
  url: string;

  publicationId = JSON.parse(localStorage.getItem('currentPublicationId'));
  currentPublication: any;
  activePointsAndVariations: any = [];
  publicationPoints: any = [];
  publicationVariations: any = [];

  options: SortablejsOptions = {};

  messageSubscription: Subscription;

  fileThumbnail: File[] = [];
  files: File[] = [];
  filesSound: File[] = [];
  filesVideo: File[] = [];

  videoList: any = [];
  videosToRemove: any = [];

  pointPermission: boolean = true;
  editExistingPoint: boolean = false;
  editingFromList: boolean = false;

  allPoints: any;
  allPointsFiltered: any;
  selectedPointFiltered: number = 0;
  showDropdownInSaved: boolean = false;
  inputSelected:boolean = false;

  newLatLonTriggered: boolean = false;

  newLat: string;
  newLon: string;
  newLatLonTitle: string;

  searchInSaved: string;
  searchInAddPoint: string;

  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
  markersStar: any = [];
  markersVariation: any = [];
  placesService: google.maps.places.PlacesService;
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
  autocomplete: google.maps.places.Autocomplete;

  debounce: number = 0;

  starIcon = {
    url: "../../../../assets/images/star.png",
    scaledSize: new google.maps.Size(50, 50)
  };
  startDragStar: google.maps.LatLng;

  variationIcon = {
    url: "../../../../assets/images/variation.png",
    scaledSize: new google.maps.Size(25, 25)
  };

  variationValidateIcon = {
    url: "../../../../assets/images/variation-validate.png",
    scaledSize: new google.maps.Size(25, 25)
  };

  videoDisabled: boolean = false;
  videoToggled: boolean = false;
  videoToggledTransition: boolean = false;
  photoDisabled: boolean = false;
  photoToggled: boolean = false;
  photoToggledTransition: boolean = false;
  soundDisabled: boolean = false;
  soundToggled: boolean = false;
  soundToggledTransition: boolean = false;
  urlToggled: boolean = false;
  urlDisabled: boolean = false;
  urlToggledTransition: boolean = false;
  urlIsValid: boolean = true;

  optionsDatePicker: DatepickerOptions = {
    minYear: getYear(new Date()) - 5,
    maxYear: getYear(new Date()) + 10,
    placeholder: 'Date de publication',
    format: 'dd-MM-yyyy',
    formatTitle: 'LLLL yyyy',
    formatDays: 'EEEEE',
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    locale: locale,
    inputClass: '',
    calendarClass: 'datepicker-default',
  };

  datePicker: any;
  timePicker: any;

  manualDate: string;

  mobileLayout: boolean = false;

  cropImg: boolean = false;
  imgToCrop: any;
  croppingFile: any;

  isSponso: boolean = false;
  sponsoWeight: number;

  constructor(private commonService: CommonService, private apiService: ApiService, private router: Router, private toastr: ToastrService, private changeDetectorRef: ChangeDetectorRef) {
    this.options = {
      onUpdate: (event: any) => {
        this.reorderPoints();
      }
    };

    setInterval(() => {
      this.changeDetectorRef.detectChanges();
      this.isLoading = false;
    }, 1000);
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.onResize();
    this.getAllPoints();

    if (this.publicationId) {
      this.apiService.getPublication(this.publicationId).subscribe(
        data => {
          this.currentPublication = data.data;
          localStorage.setItem('currentLang', JSON.stringify(this.currentPublication.lang));
          this.publicationPoints = data.data.points;
          this.activePointsAndVariations = [...data.data.points, ...data.data.variations];
          this.sponsoWeight = data.data.order_sponso;
          this.isSponso = data.data.is_sponso;
          this.populateForm();
          this.setActivePoints();
          if (this.currentPublication.published_at) {
            var t = this.currentPublication.published_at.split(/[- :]/);
            var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
            this.datePicker = new Date(d);
            let date = this.datePicker;
            this.manualDate = ((date.getDate().toString().length > 1) ? date.getDate() : ('0' + date.getDate())) + '-' + (((date.getMonth() + 1).toString().length > 1) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear();
            this.timePicker = String(date.getHours()) + ':' + String(date.getMinutes());
          } else {
            this.datePicker = new Date();
            let date = this.datePicker;
            this.manualDate = ((date.getDate().toString().length > 1) ? date.getDate() : ('0' + date.getDate())) + '-' + (((date.getMonth() + 1).toString().length > 1) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear();
            this.timePicker = String(date.getHours()) + ':' + String(date.getMinutes());
          }
          this.isLoading = false;
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.pageTitle = 'Nouvelle publication';
      this.datePicker = new Date();
      let date = this.datePicker;
      this.manualDate = ((date.getDate().toString().length > 1) ? date.getDate() : ('0' + date.getDate())) + '-' + (((date.getMonth() + 1).toString().length > 1) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + date.getFullYear();
      this.timePicker = String(date.getHours()) + ':' + String(date.getMinutes());
      this.isLoading = false;
    }
    
    this.initMap();
    this.initAutocomplete();

    this.messageSubscription = this.commonService.currentMessage.subscribe(
      message => {
        if (message == 'point saved' || message == 'point updated') {
          const subscription = this.commonService.currentPoint.subscribe(      
            point => {
              this.setPoint(point);
              this.modalNewPoint = false;
              if (message == 'point saved') {
                this.toastr.success('Point ajouté');
              } else if (message == 'point updated') {
                this.toastr.success('Point modifié');
              }
            },
            err => {
              console.log(err);
            }
          ).unsubscribe();
        }
        if (message == 'variation saved' || message == 'variation updated') {
          const subscription = this.commonService.currentPoint.subscribe(      
            data => {
              this.modalNewVariation = false;
              let variation: any = data;
              if (message == 'variation saved') {
                let marker = new google.maps.Marker({
                  icon: this.variationValidateIcon,
                  position: new google.maps.LatLng(variation.lat, variation.lon),
                  draggable: true,
                });
                let infowindow = new google.maps.InfoWindow({
                  content:  '<div style="font-size: 16px; font-weight: 400">' +
                              variation.title +
                            '</div>' +
                            '<div>' +
                              variation.description +
                            '</div>'
                });
                this.markersVariation.push({element: variation, marker: marker, infowindow: infowindow});
                this.showMarkersVariation(this.map);
                this.activePointsAndVariations.push(variation);
                this.publicationVariations.push(variation);
                this.toastr.success('Variation ' + variation.title + ' ajoutée');
              } else {
                let indexPublicationVariations = this.publicationVariations.map(function(x) {return x.id; }).indexOf(variation.id);
                this.publicationVariations[indexPublicationVariations] = variation;
                this.markersVariation[indexPublicationVariations].infowindow = new google.maps.InfoWindow({
                  content:  '<div style="font-size: 16px; font-weight: 400">' +
                              variation.title +
                            '</div>' +
                            '<div>' +
                              variation.description +
                            '</div>'
                });

                let indexActivePointsAndVariations = this.activePointsAndVariations.map(function(x) {return x.id; }).indexOf(variation.id);
                this.activePointsAndVariations[indexActivePointsAndVariations] = variation;
                this.toastr.success('Variation ' + variation.title + ' modifiée');
              }
            },
            err => {
              console.log(err);
            }
          ).unsubscribe();
        }
        if (message == 'close modal') {
          this.modalNewVariation = false;
          this.modalNewPoint = false;
        }
        this.editingFromList = false;
      },
      err => {
        console.log(err);
      }
    );

  }

  getAllPoints() {
    this.apiService.getPointsLight().subscribe(
      data => {
        this.allPoints = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  populateForm() {
    this.pageTitle = this.currentPublication.title;
    this.title = this.currentPublication.title;
    this.description = this.currentPublication.description;
    if (this.currentPublication.audio == 0 && this.currentPublication.video == 0) {
      if (this.currentPublication.url) {
        this.url = this.currentPublication.url;
        if (this.currentPublication.medias.length) {
          let thumbnail = this.currentPublication.medias[0];
          var blob = null;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + thumbnail.url);
          xhr.responseType = "blob";
          xhr.onload = () => 
          {
              blob = xhr.response;
              let format = thumbnail.url.substring(thumbnail.url.lastIndexOf('.') + 1);
              let name = thumbnail.url.substring(thumbnail.url.lastIndexOf('/') + 1);    
              var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
              this.fileThumbnail.push(file);
          }
          xhr.send();
        }
        this.videoDisabled = true;
        this.photoDisabled = true;
        this.soundDisabled = true;
        this.urlToggled = true;
        this.urlToggledTransition = true;
      } else if (this.currentPublication.medias.length > 1) {
        this.currentPublication.medias.forEach((element, index) => {
          var blob = null;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
          xhr.responseType = "blob";
          xhr.onload = () => 
          {
              blob = xhr.response;
              let format = element.url.substring(element.url.lastIndexOf('.') + 1);
              let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
              var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
              if (index == 0) {
                this.fileThumbnail.push(file);
              } else {
                this.files.push(file);
              }
          }
          xhr.send();
        });
        this.videoDisabled = true;
        this.urlDisabled = true;
        this.soundDisabled = true;
        this.photoToggled = true;
        this.photoToggledTransition = true;
      } else if (this.currentPublication.medias.length == 1) {
        let thumbnail = this.currentPublication.medias[0];
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + thumbnail.url);
        xhr.responseType = "blob";
        xhr.onload = () => 
        {
            blob = xhr.response;
            let format = thumbnail.url.substring(thumbnail.url.lastIndexOf('.') + 1);
            let name = thumbnail.url.substring(thumbnail.url.lastIndexOf('/') + 1);    
            var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
            this.fileThumbnail.push(file);
        }
        xhr.send();
      }
    } else if (this.currentPublication.audio == 1) {
      this.currentPublication.medias.forEach((element, index) => {
        if (element.url.slice(element.url.length - 3) == 'mp3') {
          var blob = null;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
          xhr.responseType = "blob";
          xhr.onload = () => 
          {
              blob = xhr.response;
              let format = element.url.substring(element.url.lastIndexOf('.') + 1);
              let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
              var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
              this.filesSound.push(file);
          }
          xhr.send();
        } else {
          var blob = null;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
          xhr.responseType = "blob";
          xhr.onload = () => 
          {
              blob = xhr.response;
              let format = element.url.substring(element.url.lastIndexOf('.') + 1);
              let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
              var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
              this.fileThumbnail.push(file);
          }
          xhr.send();
        }
      });
      this.videoDisabled = true;
      this.photoDisabled = true;
      this.urlDisabled = true;
      this.soundToggled = true;
      this.soundToggledTransition = true;
    } else if (this.currentPublication.video == 1) {
      if (this.currentPublication.medias.length) {
        let thumbnail = this.currentPublication.medias[0];
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + thumbnail.url);
        xhr.responseType = "blob";
        xhr.onload = () => 
        {
            blob = xhr.response;
            let format = thumbnail.url.substring(thumbnail.url.lastIndexOf('.') + 1);
            let name = thumbnail.url.substring(thumbnail.url.lastIndexOf('/') + 1);    
            var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
            this.fileThumbnail.push(file);
        }
        xhr.send();
      }
      this.currentPublication.video_codes.forEach((element, index) => {
        let toPush = {type: element.type, code: element.code, order: element.order};
        this.videoList.push(toPush);

        this.videosToRemove.push(element.id);
      });

      this.urlDisabled = true;
      this.photoDisabled = true;
      this.soundDisabled = true;
      this.videoToggled = true;
      this.videoToggledTransition = true;
    }
  }

  setActivePoints() {
    if (this.activePointsAndVariations.length) {
      this.activePointsAndVariations.forEach((element) => {
        if (element.point_id) {
          this.publicationVariations.push(element);
        }
      });
      this.activePointsAndVariations.sort(function(a, b){
        return a.pivot.order - b.pivot.order;
      });
      this.initMarkers();
    }
  }

  initMarkers() {
    this.publicationPoints.forEach(element => {
      this.addMarkerStar(new google.maps.LatLng(element.lat, element.lon), element.title, element.description);
    });
    this.publicationVariations.forEach(element => {
      this.addMarkerVariation(element, new google.maps.LatLng(element.lat, element.lon));
    });
  }

  initMap() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: new google.maps.LatLng(48.866667, 2.333333),
      zoom: 15
    });

    let infoWindow = new google.maps.InfoWindow({
      content: "Placeholder"
    });
    this.map.addListener("click", (mapsMouseEvent) => {
      infoWindow.close();
      infoWindow = new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
      });
      let latLon = mapsMouseEvent.latLng.toJSON();
      infoWindow.setContent(
        "Latitude : " + latLon.lat +
        "<br>" +
        "Longitude : " + latLon.lng +
        "<br>" +
        "<button id='addPointMap' style='display: flex;width: 100%;justify-content: center;align-items: center;margin-top: 5px;cursor: pointer;'>Ajouter ce point</button>"
      );
      infoWindow.open(this.map);

      google.maps.event.addListener(infoWindow, 'domready', () => {
        const el = document.getElementById('addPointMap');
        el.addEventListener('click', (event) => {
          this.newLat = String(latLon.lat);
          this.newLon = String(latLon.lng);
          this.newLatLonTriggered = true;
          infoWindow.close();
        });
      });

      const el = document.querySelector('button');
      el.addEventListener('click', (event) => {
        this.newLat = String(latLon.lat);
        this.newLon = String(latLon.lng);
        this.newLatLonTriggered = true;
        infoWindow.close();
      });
    });
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete") as HTMLInputElement
    );
    let autocomplete = this.autocomplete;
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      let city = '';
      place.address_components.forEach(element => {
        if (element.types.includes('locality')) {
          city = element.long_name || element.short_name;
        }
      });
      let title = place.name;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      let place_id = place.place_id;
      let description = place.formatted_address;
      (this.publicationId) ? this.addPoint(lat, lon, title, place_id, description, city) : this.createPublicationThenPoint(lat, lon, title, place_id, description, city);
      this.searchInAddPoint = '';
    });
  }

  onSearchInSaved(value: string, event?) {
    if (value) {
      this.showDropdownInSaved = true;
      this.allPointsFiltered = this.allPoints.filter(el => el.title.toLowerCase().includes(value.toLowerCase()));
      this.allPointsFiltered = this.allPointsFiltered.filter((point, index, self) =>
        index === self.findIndex((p) => (
          p.googleplace_id === point.googleplace_id
        ))
      );
      if (event) {
        if (event.key === 'ArrowDown') {
          if (this.selectedPointFiltered < this.allPointsFiltered.length && this.selectedPointFiltered < 5) {
            this.selectedPointFiltered = this.selectedPointFiltered + 1;
          }
          event.preventDefault();
        }
        if (event.key === 'ArrowUp') {
          if (this.selectedPointFiltered > 0) {
            this.selectedPointFiltered = this.selectedPointFiltered - 1;
          }
          event.preventDefault();
        }
        if (event.key === 'Enter') {
          this.newPointFromSaved(this.allPointsFiltered[this.selectedPointFiltered - 1]);
        }
      }
    } else {
      this.selectedPointFiltered = 0;
      this.showDropdownInSaved = false;
      this.allPointsFiltered = [];
    }
  }

  onFocus() {
    this.inputSelected = true;
  }

  onBlur() {
    setTimeout( () => this.inputSelected = false, 100 );
  }

  keepFocus(event) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
    }
  }

  newPointFromSaved(point) {
    this.searchInSaved = '';
    this.selectedPointFiltered = 0;
    this.showDropdownInSaved = false;
    this.allPointsFiltered = [];
    this.isLoading = true;
    this.editExistingPoint = true;
    this.apiService.getPoint(point.id).subscribe(
      data => {
        this.isLoading = false;
        if (data.data.utilisateur_id == this.userID) {
          this.pointPermission = true;
        } else {
          this.pointPermission = false
        }
        let point = data.data;
        if (this.mobileLayout) {
          this.setPoint(point);
        } else {
          this.commonService.changePoint(point);
          this.modalNewPoint = true;
        }
      }
    )
  }

  triggerNewLatLon() {
    this.newLatLonTriggered = true;
  }

  closeNewLatLon() {
    this.newLat = '';
    this.newLon = '';
    this.newLatLonTitle = '';
    this.newLatLonTriggered = false;
  }

  newPointFromLatLon() {
    this.addPoint(this.newLat, this.newLon, this.newLatLonTitle, '', '', '');
    this.closeNewLatLon();
  }

  createPublicationThenPoint(lat, lon, title, place_id, description, city) {
    this.isLoading = true;
    let titre = this.title || 'untitled';

    this.apiService.addPublication(titre, {description: this.description, etat: '1'}).subscribe(
      data => {
        localStorage.setItem('currentPublicationId', data.data.article_id);
        this.publicationId = JSON.parse(localStorage.getItem('currentPublicationId'));
        this.addPoint(lat, lon, title, place_id, description, city);
      },
      err => {
        console.log(err);
      }
    );
  }

  addPoint(lat, lon, title, place_id, description, city) {
    this.apiService.addPoint(
      lat, 
      lon, 
      title, 
      { article_id: this.publicationId, 
        googleplace_id: place_id, 
        description: description, 
        etat: '2',
        order: this.activePointsAndVariations.length + 1,
        city: city
      })
      .subscribe(      
      data => {
        if (data.data.create) {
          let point = {
            id: data.data.point_id,
            lat: lat,
            lon: lon,
            title: title,
            place_id: place_id,
            description: description,
            utilisateur_id: this.userID
          };
          this.pointPermission = true;
          this.editExistingPoint = false;
          if (this.mobileLayout) {
            this.setPoint(point);
          } else {
            this.commonService.changePoint(point);
            this.modalNewPoint = true;
          }
        } else {
          this.editExistingPoint = true;
          this.apiService.getPoint(data.data.point_id)      
          .subscribe(      
            data => {
              if (data.data.utilisateur_id == this.userID) {
                this.pointPermission = true;
              } else {
                this.pointPermission = false
              }
              let point = data.data;
              if (this.mobileLayout) {
                this.setPoint(point);
              } else {
                this.commonService.changePoint(point);
                this.modalNewPoint = true;
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  editPoint(i) {
    this.editingFromList = true;
    this.commonService.changePoint(this.activePointsAndVariations[i]);
    this.editExistingPoint = true;
    if (this.activePointsAndVariations[i].utilisateur_id == this.userID) {
      this.pointPermission = true;
    } else {
      this.pointPermission = false
    }
    this.modalNewPoint = true;
  }

  editVariation(i) {
    this.editingFromList = true;
    if (this.activePointsAndVariations[i].utilisateur_id == this.userID) {
      this.pointPermission = true;
    } else {
      this.pointPermission = false
    }
    this.commonService.changePoint(this.activePointsAndVariations[i]);
    this.modalNewVariation = true;
  }

  setPoint(point) {
    this.apiService.getPoint(point.id).subscribe(      
      data => {
        let variations = data.data.variations;
        if (variations) {
          variations.forEach(variation => {
            if (!(this.activePointsAndVariations.some( el => el['id'] === variation.id )) && !(this.publicationVariations.some( el => el['id'] === variation.id ))) {             
              this.publicationVariations.push(variation);
              this.addMarkerVariation(variation, new google.maps.LatLng(variation.lat, variation.lon));
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );

    let indexArrayActive = this.activePointsAndVariations.map(function(x) {return x.id; }).indexOf(point.id);
    if (indexArrayActive !== -1) {
      this.activePointsAndVariations[indexArrayActive] = point;
    } else {
      this.activePointsAndVariations.push(point);
    }

    let indexArrayPublications = this.publicationPoints.map(function(x) {return x.id; }).indexOf(point.id);
    if (indexArrayPublications !== -1) {
      this.publicationPoints[indexArrayPublications] = point;
      this.markersStar[indexArrayPublications].infowindow = new google.maps.InfoWindow({
        content:  '<div style="font-size: 16px; font-weight: 400">' +
                    point.title +
                  '</div>' +
                  '<div>' +
                    point.description +
                  '</div>'
      });
    } else {
      this.publicationPoints.push(point);
      this.addMarkerStar(new google.maps.LatLng(point.lat, point.lon), point.title, point.description);
    }

  }

  onRemovePoint(id) {
    let point = this.activePointsAndVariations.find(el => el.id == id);
    let variationToRemove = null;
    let pointToRemove = null;
    this.activePointsAndVariations = this.activePointsAndVariations.filter(function(el) { return el.id != id; });

    if (point.point_id) {
      pointToRemove = point.point_id;
      variationToRemove = point.id;
      this.removeMarker(point.id, 'variation');
    } else {
      pointToRemove = point.id;
      this.removeMarker(point.id, 'point');
    }

    this.apiService.unlinkPoint(pointToRemove, {variation_id: variationToRemove, article_id: this.publicationId}).subscribe(
      data => {
        this.reorderPoints();
        this.toastr.success('Point supprimé');
      },
      err => {
        console.log(err);
      }
    );
  }

  removeMarker(id, type) {
    if (type == 'variation') {
      let index = this.publicationVariations.map(function(x) {return x.id; }).indexOf(id);
      this.markersVariation[index].marker.setIcon(this.variationIcon);
    } else if (type == 'point') {
      let index = this.publicationPoints.map(function(x) {return x.id; }).indexOf(id);
      this.markersStar[index].marker.setMap(null);
      this.markersStar.splice(index, 1);
      this.publicationPoints.splice(index, 1);  
    }
  }

  reorderPoints() {
    const points = [];
    this.activePointsAndVariations.forEach((element, index) => {
      if (element.order !== index + 1) {
        if (element.point_id) {
          let point = {point_id: element.point_id, variation_id: element.id, order: index + 1};
          points.push(point);
        } else {
          let point = {point_id: element.id, variation_id: null, order: index + 1};
          points.push(point);
        }
      }
    });
    if (points.length) {
      this.apiService.orderPublicationPoints(this.publicationId, points).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  addMarkerStar(position, title, description) {
    let marker = new google.maps.Marker({
      icon: this.starIcon,
      position: position,
      draggable: true,
    });
    let infowindow = new google.maps.InfoWindow({
      content:  '<div style="font-size: 16px; font-weight: 400">' +
                  title +
                '</div>' +
                '<div>' +
                  description +
                '</div>'
    });
    this.markersStar.push({marker: marker, infowindow: infowindow});
    this.showMarkersStar(this.map);
  }

  showMarkersStar(map: google.maps.Map) {
    this.bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.markersStar.length; i++) {
      this.markersStar[i].marker.setMap(map);
      this.bounds.extend(this.markersStar[i].marker.getPosition());
      this.markersStar[i].marker.addListener("click", (element) => {
        this.displayInfoWindowStar(i);
      });
      this.markersStar[i].marker.addListener("dragstart", (evt) => {
        this.startDragStar = new google.maps.LatLng(evt.latLng.lat(), evt.latLng.lng());
      });
      this.markersStar[i].marker.addListener("dragend", (evt) => {
        this.createVariation(this.publicationPoints[i], evt.latLng.lat(), evt.latLng.lng());
        this.markersStar[i].marker.setPosition(this.startDragStar);
      });
    }
    map.fitBounds(this.bounds);
  }

  displayInfoWindowStar(index) {
    this.markersStar.forEach((marker, index) => {
      marker.infowindow.close();
    });
    this.markersStar[index].infowindow.open(this.map, this.markersStar[index].marker);
  }

  createVariation(point, lat, lon) {
    if (this.debounce >= (Date.now() - 20)) return;
    this.debounce = Date.now();

    point.newVariationLat = lat;
    point.newVariationLon = lon;
    this.pointPermission = true;
    this.editingFromList = false;
    this.commonService.changePoint(point);
    this.modalNewVariation = true;
  }

  addMarkerVariation(element, position) {
    let icon = this.variationIcon;
    if (this.activePointsAndVariations.some( el => el['id'] === element.id )) {
      icon = this.variationValidateIcon;
    }
    let marker = new google.maps.Marker({
      icon: icon,
      position: position,
      draggable: true,
    });
    let infowindow = new google.maps.InfoWindow({
      content:  '<div style="font-size: 16px; font-weight: 400">' +
                  element.title +
                '</div>' +
                '<div>' +
                  element.description +
                '</div>'
    });
    this.markersVariation.push({element: element, marker: marker, infowindow: infowindow});
    this.showMarkersVariation(this.map);
  }

  showMarkersVariation(map) {
    for (let i = 0; i < this.markersVariation.length; i++) {
      this.markersVariation[i].marker.setMap(map);
      this.markersVariation[i].marker.addListener("mouseover", () => {
        this.displayInfoWindowVariation(i);
      });
      this.markersVariation[i].marker.addListener("mouseout", () => {
        this.markersVariation[i].infowindow.close();
      });
      this.markersVariation[i].marker.addListener("click", () => {
        if (!(this.activePointsAndVariations.some( el => el['id'] === this.markersVariation[i].element.id ))) {
          if (this.debounce >= (Date.now() - 20)) return;
          this.debounce = Date.now();
          this.setVariation(this.markersVariation[i]);
        }
      });
      this.markersVariation[i].marker.addListener("dragend", (evt) => {
        if (this.debounce >= (Date.now() - 20)) return;
        this.debounce = Date.now();
        if (this.markersVariation[i].element.utilisateur_id == this.userID) {
          let variation = this.markersVariation[i];
          variation.element.lat = String(evt.latLng.lat());
          variation.element.lon = String(evt.latLng.lng());
          this.apiService.addVariation(variation.element.point_id, variation.element.title, variation.element.lat, variation.element.lon, {variation_id: variation.element.id, article_id: this.publicationId})
          .subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }
  }

  setVariation(variation) {
    this.apiService.addVariation(variation.element.point_id, variation.element.title, variation.element.lat, variation.element.lon, {variation_id: variation.element.id, article_id: this.publicationId}).subscribe(
      data => {
        this.activePointsAndVariations.push(variation.element);
        this.reorderPoints();
        this.toastr.success('Variation ' + variation.element.title + ' ajoutée');
        variation.marker.setIcon(this.variationValidateIcon);
      },
      err => {
        console.log(err);
      }
    );
  }

  displayInfoWindowVariation(index) {
    this.markersVariation.forEach((marker, index) => {
      marker.infowindow.close();
    });
    this.markersVariation[index].infowindow.open(this.map, this.markersVariation[index].marker);
  }

  onToggleMedia(media) {
    this.videoToggled = false;
    this.videoToggledTransition = false;
    this.photoToggled = false;
    this.photoToggledTransition = false;
    this.soundToggled = false;
    this.soundToggledTransition = false;
    this.urlToggled = false;
    this.urlToggledTransition = false;
    switch (media) {
      case 'video':
        this.videoToggled = true;
        setTimeout(() => {
          this.videoToggledTransition = true;
        }, 1);
        break;
      case 'photo':
          this.photoToggled = true;
          setTimeout(() => {
            this.photoToggledTransition = true;
          }, 1);
        break;
      case 'sound':
        this.soundToggled = true;
        setTimeout(() => {
          this.soundToggledTransition = true;
        }, 1);
        break;
      case 'url':
        this.urlToggled = true;
        setTimeout(() => {
          this.urlToggledTransition = true;
        }, 1);
        break;
    }
  }

  onSelect(event) {
    let files = event.addedFiles;
    files.forEach(file => {
      this.files.push(file);
    });
  }

  onCropImg(file) {
    this.imgToCrop = file;
    this.cropImg = true;
    this.croppingFile = file;
  }

  onCroppedImg(e) {
    if (e) {
      if (this.files.indexOf(this.croppingFile) !== -1) {
        this.files.splice(this.files.indexOf(this.croppingFile), 1);
        this.currentPublication.medias.forEach(media => {
          this.unlinkMedia(media.id);
        });
        this.files.push(e);
      } else if (this.fileThumbnail.indexOf(this.croppingFile) == 0) {
        this.fileThumbnail.splice(this.fileThumbnail.indexOf(this.croppingFile), 1);
        this.currentPublication.medias.forEach(media => {
          this.unlinkMedia(media.id);
        });
        this.fileThumbnail.push(e);
      }
    }
    this.cropImg = false;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.currentPublication.medias.forEach(media => {
      this.unlinkMedia(media.id);
    });
    if (!this.files.length) {
      this.videoDisabled = false;
      this.soundDisabled = false;
      this.urlDisabled = false;  
    }
  }

  onSelectThumbnail(event) {
    if (this.fileThumbnail.length) return;
    let files = event.addedFiles;
    this.fileThumbnail.push(files[0]);
  }

  onRemoveThumbnail(event) {
    this.fileThumbnail.splice(this.fileThumbnail.indexOf(event), 1);
    this.currentPublication.medias.forEach(media => {
      this.unlinkMedia(media.id);
    });
  }

  onSelectVideo(event) {
    this.filesVideo.push(...event.addedFiles);
  }

  onRemoveVideo(event) {
    this.filesVideo.splice(this.files.indexOf(event), 1);
  }
  
  addVideo(type: number) {
    this.videoList.push({type: type, code: '', order: this.videoList.length + 1});
    this.soundDisabled = true;
    this.photoDisabled = true;
    this.urlDisabled = true;
  }

  onDeleteVideo(video) {
    this.videoList = this.videoList.filter(function(el) { return el != video; });
    if (this.videoList.length) {
      this.videoList.forEach((element, index) => {
        element.order = index + 1;
      });
    } else {
      this.soundDisabled = false;
      this.photoDisabled = false;
      this.urlDisabled = false;
    }
  }

  onSelectSound(event) {
    this.filesSound.push(...event.addedFiles);
    this.videoDisabled = true;
    this.photoDisabled = true;
    this.urlDisabled = true;
  }

  onRemoveSound(event) {
    this.filesSound.splice(this.files.indexOf(event), 1);
    if (!this.filesSound.length) {
      this.videoDisabled = false;
      this.photoDisabled = false;
      this.urlDisabled = false;
    }
  }

  onUrlKeyPress() {
    if (this.url) {
      if (!this.url.startsWith('http://') && !this.url.startsWith('https://')) {
        this.urlIsValid = false;
      } else {
        this.urlIsValid = true;
      }
      this.videoDisabled = true;
      this.photoDisabled = true;
      this.soundDisabled = true;
    } else {
      this.urlIsValid = true;
      this.videoDisabled = false;
      this.photoDisabled = false;
      this.soundDisabled = false; 
    }
  }

  unlinkMedia(media_id: any) {
    this.apiService.unlinkMedia(media_id).subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
  }

  openModalExplanations() {
    this.modalExplanations = true;
  }

  closeModalExplanations(event) {
    this.modalExplanations = false;
  }

  getDateString(dateObject?) {
    let date;
    if (dateObject) {
      date = new Date(dateObject);
    } else {
      date = new Date();
    }
  
    let dd = date.getDate();
    let mm = date.getMonth()+1; 
    let yyyy = date.getFullYear();
  
    let day: string;
    let month: string;
  
    (dd<10)? day = '0' + String(dd) : day = String(dd);
    (mm<10)? month = '0' + String(mm) : month = String(mm);
    
    let today = String(yyyy) + '-' + month + '-' + day;
    return today;
  }

  onManualDateKeyPress() {
    if (this.manualDate) {
      if (this.manualDate.length === 2) {
        this.manualDate = this.manualDate + '-';
      }
      if (this.manualDate.length === 5) {
        this.manualDate = this.manualDate + '-';
      }
    }
  }

  async saveAndDestroy(etat?) {
    if (this.urlToggled && !this.urlIsValid) {
      this.toastr.warning('URL non valide', '', {
        timeOut: 2000,
      });
      return;
    }

    this.isLoading = true;

    if (this.title) {

      //Thumbnail
      let files = this.fileThumbnail;

      //Photos
      if (this.files.length) {
        files = this.fileThumbnail.concat(this.files);
      }

      //Resize files
      let filesResized = [];
      for await (const file of files) {
        await this.resizeImage(file, 1500, 1500).then(blob => {
          let resizedFile = new File([blob], file.name, {type: blob.type});
          filesResized.push(resizedFile);
        }, err => {
          console.error("Photo error", err);
        });
      };

      //Audio
      let audio = '0';
      if (this.filesSound.length) {
        files = this.fileThumbnail.concat(this.filesSound);
        audio = '1';
      }

      //Url
      let url = this.url;

      //Vidéos
      let video = '0';
      //We remove all videos from the article to add all the new ones if the user changed some
      if (this.videosToRemove.length) {
        this.apiService.articleUnlinkVideos(this.publicationId, this.videosToRemove).subscribe(
          data => {
          },
          err => {
            console.log(err);
          }
        );
      }
      if (this.videoList.length) {
        video = '1';
      }

      let dateFromPicker = this.getDateString(this.datePicker) + ' ' + this.timePicker;

      let dateFromManual = this.manualDate.substr(6, 9) + '-' + this.manualDate.substr(3, 2) + '-' + this.manualDate.substr(0, 2);
      //Check if date is a correct value
      if (!new Date(dateFromManual).getTime()) {
        this.toastr.warning('Date non valide', '', {
          timeOut: 2000,
        });
        return;
      }

      let dateFromPickerAndManual = dateFromManual + ' ' + this.timePicker;

      this.apiService.addPublication(this.title, {article_id: this.publicationId, description: this.description, etat: etat, medias: filesResized, url: url, audio: audio, video: video, video_codes: this.videoList, published_at: dateFromPickerAndManual, is_sponso: this.isSponso, order_sponso: String(this.sponsoWeight)}).subscribe(
        data => {
          this.router.navigate(['/publications']);
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.router.navigate(['/publications']);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1000) {
      this.mobileLayout = true;
    } else {
      this.mobileLayout = false;
    }
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  async resizeImage(file:File, maxWidth:number, maxHeight:number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
          let width = image.width;
          let height = image.height;
          
          if (width <= maxWidth && height <= maxHeight) {
              resolve(file);
          }

          let newWidth;
          let newHeight;

          if (width > height) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
          } else {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
          }

          let canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          let context = canvas.getContext('2d');

          context.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

}