import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-step-for',
  templateUrl: './step-for.component.html',
  styleUrls: ['./step-for.component.scss']
})
export class StepForComponent implements OnInit {

  isLoading: boolean = false;

  pageTitle: string;
  title: string;
  description: string;
  files: File[] = [];
  youtube: string;
  routeId = JSON.parse(localStorage.getItem('currentRouteId'));
  currentRoute: any;

  content: any;
  theme: any;
  time: any;
  distance: any;

  cropImg: boolean = false;
  imgToCrop: any;
  croppingFile: any;

  constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.apiService.getRoute(this.routeId).subscribe(
      data => {
        this.currentRoute = data.data;       
        localStorage.setItem('currentLang', JSON.stringify(this.currentRoute.lang));
        this.pageTitle = 'Édition du circuit ' + this.currentRoute.title;
        this.title = this.currentRoute.title;
        this.description = this.currentRoute.description;
        this.youtube = this.currentRoute.youtube;
        this.content = this.currentRoute.content;
        this.theme = this.currentRoute.theme;
        this.time = this.currentRoute.time;
        this.distance = this.currentRoute.distance;
        if (!this.currentRoute.start_step_blocking_geolocation) this.currentRoute.start_step_blocking_geolocation = 1;
        if (!this.currentRoute.end_step_blocking_geolocation) this.currentRoute.end_step_blocking_geolocation = this.currentRoute.points.length + this.currentRoute.variations.length;
        if (this.currentRoute.medias.length) {
          this.currentRoute.medias.forEach((element, index) => {
            var blob = null;
            var xhr = new XMLHttpRequest();
            xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
            xhr.responseType = "blob";
            xhr.onload = () => 
            {
                blob = xhr.response;
                let format = element.url.substring(element.url.lastIndexOf('.') + 1);
                let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
                var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
                this.files.push(file);
            }
            xhr.send();
          });
        }
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  onSelect(event) {
    let files = event.addedFiles;
    files.forEach(file => {
      this.files.push(file);
    });
  }

  onCropImg(file) {
    this.imgToCrop = file;
    this.cropImg = true;
    this.croppingFile = file;
  }

  onCroppedImg(e) {
    if (e) {
      if (this.files.indexOf(this.croppingFile) !== -1) {
        this.files.splice(this.files.indexOf(this.croppingFile), 1);
        this.files.push(e);
      }
    }
    this.cropImg = false;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async validateRoute() {
    this.isLoading = true;

    let filesResized = [];
    for await (const file of this.files) {
      await this.resizeImage(file, 1500, 1500).then(blob => {
        let resizedFile = new File([blob], file.name, {type: blob.type});
        filesResized.push(resizedFile);
      }, err => {
        console.error("Photo error", err);
      });
    };

    this.apiService.addRoute(this.title, {route_id: this.routeId, description: this.description, youtube: this.youtube, trace: this.currentRoute.trace, theme: this.theme, time: this.time, distance: this.distance, content: this.content, medias: filesResized, etat: '2', blocking_geolocation: this.currentRoute.blocking_geolocation, start_step_blocking_geolocation: this.currentRoute.start_step_blocking_geolocation, end_step_blocking_geolocation: this.currentRoute.end_step_blocking_geolocation}).subscribe(
      data => {
        document.querySelector(".create-circuit").scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.isLoading = false;
        this.toastr.success('Circuit publié ! Vous allez être redirigé vers la page des circuits dans 3 secondes.', 'Bravo !', {
          timeOut: 3000,
        });
        this.redirectToHome();
      },
      err => {
        console.log(err);
      }
    );
  }

  redirectToHome() {
    setTimeout(() => {
      this.router.navigate(['/circuits']);
    }, 3000);
  }

  async resizeImage(file:File, maxWidth:number, maxHeight:number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
          let width = image.width;
          let height = image.height;
          
          if (width <= maxWidth && height <= maxHeight) {
              resolve(file);
          }

          let newWidth;
          let newHeight;

          if (width > height) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
          } else {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
          }

          let canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          let context = canvas.getContext('2d');

          context.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

}
