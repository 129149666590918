import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-create-quizz',
  templateUrl: './create-quizz.component.html',
  styleUrls: ['./create-quizz.component.scss']
})
export class CreateQuizzComponent implements OnInit {

  @Input() point: any;
  @Input() allPoints: any;
  scoreMax: number = 0;

  quizz: any;
  quizzID: any;
  isBlocking: boolean;
  minPoints: any;

  onEtape: boolean = true;
  onParcours: boolean = false;

  quizzQuestions: any = [];
  quizzAnswer: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.quizz = this.point.quizz;
    if (this.quizz.statut == 2) {
      this.onEtape = false;
      this.onParcours = true;
    }
    this.quizzID = this.quizz.id;
    this.isBlocking = this.quizz.is_blocking;
    this.minPoints = this.quizz.min_score;
    this.quizzQuestions = this.quizz.questions;
    this.quizzQuestions.forEach(element => {
      (element.score) ? element.countScore = true : element.countScore = false;
      (element.letter) ? element.hasLetter = true : element.hasLetter = false;
      element.newQuestionMedia = [];
      element.newAnswerMedia = [];
      element.falseAnswerMedia = [];
      element.media.forEach(media => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + media.url);
        xhr.responseType = "blob";
        xhr.onload = () => {
          blob = xhr.response;
          let format = media.url.substring(media.url.lastIndexOf('.') + 1);
          let name = media.file_name;
          var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
          if (media.name == "question_media") {
            element.newQuestionMedia.push(file);
          } else if (media.name == "answer_media") {
            element.newAnswerMedia.push(file);
          } else if (media.name == "wrong_answer_media") {
            element.falseAnswerMedia.push(file);
          }
        }
        xhr.send();
      });
    });

    this.apiService.getRoute(this.quizz.trajet_id).subscribe(
      data => {
        let quizzes = data.data.quizzs;

        let currentPointId;
        (this.quizz.variation_id) ? currentPointId = this.quizz.variation_id : currentPointId = this.quizz.point_id;

        quizzes.forEach(quizz => {
          if (quizz.variation_id) quizz.point_id = quizz.variation_id;
          let order = this.allPoints.findIndex(x => x.id === quizz.point_id);
          quizz.order = order;
        });

        quizzes = quizzes.sort((a, b) => a.order - b.order);
      
        let index = quizzes.findIndex(x => x.point_id === currentPointId);        
        let quizzesToCount = quizzes.slice(0, index);

        let ids = [];
        quizzesToCount.forEach(quizz => {
          ids.push(quizz.id);
        });

        if (ids.length) {
          this.apiService.getQuizz(ids).subscribe(
            data => {
              let res = data.data;
              res.forEach(quizz => {
                if (quizz.questions.length) {
                  quizz.questions.forEach(question => {
                    this.scoreMax = this.scoreMax + question.score;
                  });
                }
              });
            },
            err => {
              console.log(err);
            }
          ); 
        }

      },
      err => {
        console.log(err);
      }
    );
  }

  toggleBlocking(event) {
    if (event.target.checked) {
      this.isBlocking = true;
    } else {
      this.minPoints = 0;
    }
  }

  toggleOnEtape(event) {
    if (event.target.checked) {
      this.onParcours = false;
      this.onEtape = true;
    } else {
      this.onEtape = false;
    }
  }

  toggleOnParcours(event) {
    if (event.target.checked) {
      this.onEtape = false;
      this.onParcours = true;
    } else {
      this.onParcours = false;
    }
  }


  toggleLetter(event, i) {
    if (event.target.checked) {
      this.quizzQuestions[i].hasLetter = true;
    } else {
      this.quizzQuestions[i].letter = '';
    }
  }

  toggleCompta(event, i) {
    if (event.target.checked) {
      this.quizzQuestions[i].countScore = true;
    } else {
      this.quizzQuestions[i].countScore = false;
    }
  }

  toggleKey(event, i) {
    if (event.target.checked) {
      this.quizzQuestions[i].key = true;
    } else {
      this.quizzQuestions[i].key = false;
    }
  }

  onAddQuestion() {
    this.apiService.addQuizzQuestion(this.quizz.id, 'Nouvelle question', 'Description', 1).subscribe(
      data => {
        this.apiService.getQuizz(this.quizz.id).subscribe(
          data => {
            this.quizzQuestions.push(data.data[0].questions.slice(-1)[0]);
            this.quizzQuestions[this.quizzQuestions.length - 1].newQuestionMedia = [];
            this.quizzQuestions[this.quizzQuestions.length - 1].newAnswerMedia = [];
            this.quizzQuestions[this.quizzQuestions.length - 1].falseAnswerMedia = [];
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  onDeleteQuestion(index) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer cette question ?\nCette action est irréversible.')){
      this.apiService.deleteQuizzQuestion(this.quizzQuestions[index].id).subscribe(
        data => {
          this.quizzQuestions.splice(index, 1);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onOptionChange(i, index) {
    this.quizzQuestions[i].options.forEach((option, order) => {
      let isSolution;
      (order == index) ? isSolution = 1 : isSolution = 0;   
      this.apiService.editQuizzQuestionOption(option.id, {title: option.title, is_solution: isSolution, order: order + 1}).subscribe(
        data => {
          this.apiService.getQuizz(this.quizz.id).subscribe(
            data => {
              this.quizzQuestions[i].options = data.data[0].questions[i].options;
            },
            err => {
              console.log(err);
            }
          );
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  onDeleteOption(indexQuestions, indexOptions) {
    this.apiService.deleteQuizzQuestionOption(this.quizzQuestions[indexQuestions].options[indexOptions].id).subscribe(
      data => {
        this.apiService.getQuizz(this.quizz.id).subscribe(
          data => {
            this.quizzQuestions[indexQuestions].options = data.data[0].questions[indexQuestions].options;
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  onAddOption(i) {
    this.apiService.addQuizzQuestionOption(this.quizzQuestions[i].id, 'Nouvelle option', 0, this.quizzQuestions[i].options.length + 1).subscribe(
      data => {
        this.apiService.getQuizz(this.quizz.id).subscribe(
          data => {
            this.quizzQuestions[i].options.push(data.data[0].questions[i].options[data.data[0].questions[i].options.length - 1]);
          },
          err => {
            console.log(err);
          }
        );
      },
      err => {
        console.log(err);
      }
    );
  }

  changeQuizzType(i) {
    this.apiService.editQuizzQuestion(this.quizzQuestions[i].id, this.quizzQuestions[i].title, {quizz_type: this.quizzQuestions[i].quizz_type}).subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
  }

  onSelectImgNewQuestion(event, question) {
    if (question.newQuestionMedia.length) {
      question.newQuestionMedia.length = 0;
    }
    question.newQuestionMedia.push(event.addedFiles[0]);
  }

  onRemoveImgNewQuestion(question, event) {
    question.newQuestionMedia.splice(question.newQuestionMedia.indexOf(event), 1);
    this.apiService.deleteQuizzQuestionMedia(question.id, 'question_media').subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
  }

  onSelectImgNewAnswer(event, question) {
    if (question.newAnswerMedia.length) {
      question.newAnswerMedia.length = 0;
    }
    question.newAnswerMedia.push(event.addedFiles[0]);
  }

  onRemoveImgNewAnswer(question, event) {
    question.newAnswerMedia.splice(question.newAnswerMedia.indexOf(event), 1);
    this.apiService.deleteQuizzQuestionMedia(question.id, 'answer_media').subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
  }

  onSelectImgFalseAnswer(event, question) {
    if (question.falseAnswerMedia.length) {
      question.falseAnswerMedia.length = 0;
    }
    question.falseAnswerMedia.push(event.addedFiles[0]);
  }

  onRemoveImgFalseAnswer(question, event) {
    question.falseAnswerMedia.splice(question.falseAnswerMedia.indexOf(event), 1);
    this.apiService.deleteQuizzQuestionMedia(question.id, 'wrong_answer_media').subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    let statut = 1;
    if (this.onParcours) statut = 2;
    
    this.apiService.editQuizz(this.quizz.id, {is_blocking: this.isBlocking, min_score: this.minPoints, statut: statut}).subscribe(
      data => {
      },
      err => {
        console.log(err);
      }
    );
    this.quizzQuestions.forEach((question, order) => {
      this.apiService.editQuizzQuestion(question.id, question.title, {
        description: question.description || ' ',
        solution: question.solution || ' ',
        title_answer: question.title_answer || ' ',
        description_answer: question.description_answer || ' ',
        score: (question.countScore) ? question.score : 0,
        title_wrong_answer_false: question.title_wrong_answer_false || ' ',
        description_wrong_answer_false: question.description_wrong_answer_false || ' ',
        question_media: question.newQuestionMedia[0],
        answer_media: question.newAnswerMedia[0],
        wrong_answer_media: question.falseAnswerMedia[0],
        key: (question.key) ? 1 : 0,
        order: order + 1,
        letter: question.letter
      }).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
      question.options.forEach((option, order) => {
        this.apiService.editQuizzQuestionOption(option.id, {title: option.title, is_solution: option.is_solution, order: order + 1}).subscribe(
          data => {
          },
          err => {
            console.log(err);
          }
        );
      });
    });
  }

}
