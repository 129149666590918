import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AuthComponent } from './auth/auth.component';
import { StepOneComponent } from './contents/create-circuit/step-one/step-one.component';
import { StepTwoComponent } from './contents/create-circuit/step-two/step-two.component';
import { StepThreeComponent } from './contents/create-circuit/step-three/step-three.component';
import { StepForComponent } from './contents/create-circuit/step-for/step-for.component';
import { CircuitsComponent } from './contents/circuits/circuits.component';
import { PointsComponent } from './contents/points/points.component';
import { TraductionsComponent } from './contents/traductions/traductions.component';
import { LanguesComponent } from './my-app/langues/langues.component';
import { PersonnalisationComponent } from './my-app/personnalisation/personnalisation.component';
import { UsersComponent } from './my-app/users/users.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { ColorPickerModule } from 'ngx-color-picker';

import { ToastrModule } from 'ngx-toastr';

import { GoogleMapsModule } from '@angular/google-maps'

import { SortablejsModule } from 'ngx-sortablejs';

import { PublicationsComponent } from './contents/publications/publications.component';
import { StepOnePublicationComponent } from './contents/create-publication/step-one-publication/step-one-publication.component';
import { StepTwoPublicationComponent } from './contents/create-publication/step-two-publication/step-two-publication.component';
import { ModalCreatePointComponent } from './modal-create-point/modal-create-point.component';
import { ModalCreateVariationComponent } from './modal-create-variation/modal-create-variation.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PersonnalisationGeneraleComponent } from './my-app/personnalisation/personnalisation-generale/personnalisation-generale.component';
import { PersonnalisationAlgorithmeComponent } from './my-app/personnalisation/personnalisation-algorithme/personnalisation-algorithme.component';
import { PersonnalisationCategoriesComponent } from './my-app/personnalisation/personnalisation-categories/personnalisation-categories.component';
import { SafePipePipe } from './pipes/safe-pipe.pipe';
import { EditPointComponent } from './contents/points/edit-point/edit-point.component';
import { CreatePointComponent } from './contents/points/create-point/create-point.component';

import { EditorModule } from '@tinymce/tinymce-angular';
import { DatepickerModule } from 'ng2-datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ModalDuplicateComponent } from './modal-duplicate/modal-duplicate.component';
import { StatsComponent } from './my-app/stats/stats.component';

import { ChartsModule } from 'ng2-charts';
import { SingleStatsComponent } from './my-app/single-stats/single-stats.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { NgxPaginationModule } from 'ngx-pagination';

import { ImageCropperModule } from 'ngx-image-cropper';
import { ImgCropperComponent } from './contents/img-cropper/img-cropper.component';
import { CreateQuizzComponent } from './create-quizz/create-quizz.component';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AccountsComponent } from './my-app/accounts/accounts.component';
import { CircuitExitsComponent } from './my-app/circuit-exits/circuit-exits.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    LoadingSpinnerComponent,
    AuthComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepForComponent,
    CircuitsComponent,
    PointsComponent,
    TraductionsComponent,
    LanguesComponent,
    PersonnalisationComponent,
    UsersComponent,
    PublicationsComponent,
    StepOnePublicationComponent,
    StepTwoPublicationComponent,
    ModalCreatePointComponent,
    ModalCreateVariationComponent,
    PersonnalisationGeneraleComponent,
    PersonnalisationAlgorithmeComponent,
    PersonnalisationCategoriesComponent,
    SafePipePipe,
    EditPointComponent,
    CreatePointComponent,
    ModalDuplicateComponent,
    StatsComponent,
    SingleStatsComponent,
    ImgCropperComponent,
    CreateQuizzComponent,
    AccountsComponent,
    CircuitExitsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDropzoneModule,
    DropzoneModule,
    BrowserAnimationsModule,
    DragDropModule,
    ColorPickerModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      maxOpened: 1,
      autoDismiss: true,
    }),
    GoogleMapsModule,
    EditorModule,
    DatepickerModule,
    NgxMaterialTimepickerModule,
    ChartsModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    NgxPaginationModule,
    SortablejsModule.forRoot({ animation: 150 }),
    ImageCropperModule,
    NgxQRCodeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
