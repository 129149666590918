<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Mes publications
    </div>
    <div class="search">
      <div class="searchbar">
        <input
          type="text"
          placeholder="Rechercher"
          (input)="onSearchChange($event.target.value)"
          />
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
            <g>
                <g>
                    <path d="M430.827,86.605c-49.161-51.53-124.712-59.44-180.6-28.393c-4.829,2.682-6.568,8.772-3.886,13.6
                        c2.682,4.83,8.772,6.567,13.6,3.886c51.118-28.401,115.436-18.236,156.371,24.671c42.236,44.811,46.992,111.607,14.317,161.128
                        c-45.008,68.362-142.217,78.664-200.5,20.37c-39.273-39.259-50.651-102.572-22.282-154.093c2.662-4.84,0.897-10.922-3.942-13.584
                        c-4.839-2.66-10.92-0.897-13.584,3.942c-0.613,1.115-1.703,3.193-2.754,5.324h0.001c-29.088,59.294-15.854,128.301,28.418,172.556
                        c67.148,67.162,179.31,55.53,231.345-23.507C484.648,215.946,480.07,138.85,430.827,86.605z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M456.438,55.562c-74.091-74.081-194.651-74.084-268.745,0.001c-66.628,66.638-73.801,171.115-19.542,245.856
                        l-31.167,31.16c-10.927-5.23-24.745-3.542-34.155,5.869L14.667,426.62c-19.547,19.534-19.563,51.168-0.005,70.713
                        c19.539,19.552,51.172,19.56,70.715,0.002l88.175-88.164c9.406-9.408,11.1-23.226,5.868-34.154l31.163-31.17
                        c74.758,54.271,179.227,47.079,245.855-19.54C530.522,250.214,530.522,129.656,456.438,55.562z M159.407,395.027L71.23,483.193
                        c-11.719,11.728-30.689,11.739-42.424-0.005c-11.73-11.721-11.737-30.691,0.002-42.422l88.164-88.174
                        c3.897-3.898,10.242-3.9,14.139,0l28.295,28.295C163.306,384.785,163.306,391.129,159.407,395.027z M166.479,359.67
                        l-14.148-14.148l28.481-28.474c2.234,2.479,4.529,4.909,6.88,7.259c2.351,2.351,4.78,4.647,7.261,6.882L166.479,359.67z
                          M442.293,310.163c-66.277,66.267-173.635,66.831-240.458,0c-66.743-66.736-66.346-174.099,0-240.456
                        c66.289-66.28,174.158-66.291,240.457,0C508.577,136.001,508.577,243.87,442.293,310.163z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M230.37,84.108c-0.07-0.09-0.15-0.17-0.24-0.26c-3.911-3.901-10.242-3.901-14.152,0c-3.901,3.911-3.901,10.242,0,14.152
                        c0.09,0.09,0.17,0.17,0.26,0.25c3.931,3.651,10.072,3.561,13.892-0.25C233.94,94.179,234.03,88.038,230.37,84.108z"/>
                </g>
            </g>
        </svg>
      </div>
    </div>
    <div class="create">
      <a style="cursor:pointer;" (click)="createNewPublication()">
        Créer une publication
      </a>
    </div>
  </div>
  <div class="filter">
    <div class="title">
      Filtrer par :
    </div>
    <div class="btn published" [class.active]="filteredByPublished" (click)="filterPublished()">
      Publiée
    </div>
    <div class="btn draft" [class.active]="filteredByDraft" (click)="filterDraft()">
      Brouillon
    </div>
  </div>
  <div class="content-page" *ngIf="!isLoading">
    <div class="publications">
      <ng-container *ngFor="let publication of userPublicationsFiltered | paginate: { itemsPerPage: 10, currentPage: page }; let index = index;">
        <div *ngIf="publication.etat!==0" class="single-publication {{index}}">
          <div class="image" (click)="onEdit(publication.id, $event)">
            <img *ngIf="publication.medias[0]" src="{{ publication.medias[0].url }}">
            <img *ngIf="!publication.medias[0]" src="">
            <div class="hover">
              <div class="link">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 511.997 511.997" style="enable-background:new 0 0 511.997 511.997;" xml:space="preserve">
                  <g transform="translate(1 1)">
                    <g>
                      <g>
                        <path d="M211.26,389.24l-60.331,60.331c-25.012,25.012-65.517,25.012-90.508,0.005c-24.996-24.996-24.996-65.505-0.005-90.496
                          l120.683-120.683c24.991-24.992,65.5-24.992,90.491,0c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17
                          c-41.654-41.654-109.177-41.654-150.831,0L30.247,328.909c-41.654,41.654-41.654,109.177,0,150.831
                          c41.649,41.676,109.177,41.676,150.853,0l60.331-60.331c8.331-8.331,8.331-21.839,0-30.17S219.591,380.909,211.26,389.24z"/>
                        <path d="M479.751,30.24c-41.654-41.654-109.199-41.654-150.853,0l-72.384,72.384c-8.331,8.331-8.331,21.839,0,30.17
                          c8.331,8.331,21.839,8.331,30.17,0l72.384-72.384c24.991-24.992,65.521-24.992,90.513,0c24.991,24.991,24.991,65.5,0,90.491
                          L316.845,283.638c-24.992,24.992-65.5,24.992-90.491,0c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17
                          c41.654,41.654,109.177,41.654,150.831,0l132.736-132.736C521.405,139.418,521.405,71.894,479.751,30.24z"/>
                      </g>
                    </g>
                  </g>
                </svg>
                <a [href]="publication.app_link" target="_blank"></a>
              </div>
              <div class="share">
                <svg height="512pt" viewBox="0 0 512 512.00508" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m453.335938 512.003906h-394.667969c-32.363281 0-58.66406275-26.304687-58.66406275-58.664062v-309.335938c0-32.363281 26.30078175-58.664062 58.66406275-58.664062h74.667969c8.832031 0 16 7.167968 16 16 0 8.832031-7.167969 16-16 16h-74.667969c-14.699219 0-26.664063 11.964844-26.664063 26.664062v309.335938c0 14.695312 11.964844 26.664062 26.664063 26.664062h394.667969c14.699218 0 26.667968-11.96875 26.667968-26.664062v-181.335938c0-8.832031 7.167969-16 16-16 8.832032 0 16 7.167969 16 16v181.335938c0 32.359375-26.304687 58.664062-58.667968 58.664062zm0 0"/><path d="m143.980469 341.0625c-1.171875 0-2.347657-.128906-3.519531-.429688-7.230469-1.683593-12.457032-7.871093-12.457032-15.292968v-32c0-114.6875 93.3125-208 208-208h5.332032v-69.335938c0-6.527344 3.96875-12.394531 10.027343-14.847656 6.035157-2.429688 12.96875-.960938 17.492188 3.753906l138.667969 144c5.972656 6.1875 5.972656 16 0 22.1875l-138.667969 144c-4.523438 4.714844-11.5 6.167969-17.492188 3.753906-6.058593-2.453124-10.027343-8.320312-10.027343-14.847656v-69.332031h-25.34375c-67.113282 0-127.425782 37.289063-157.417969 97.300781-2.753907 5.546875-8.535157 9.089844-14.59375 9.089844zm192.023437-223.722656c-89.601562 0-163.796875 67.304687-174.65625 154.023437 38.78125-43.261719 94.398438-68.691406 154.644532-68.691406h41.34375c8.832031 0 16 7.167969 16 16v45.652344l100.457031-104.320313-100.457031-104.320312v45.65625c0 8.832031-7.167969 16-16 16zm0 0"/></svg>
                <a [href]="publication.fb_share" target="_blank"></a>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="title">
              <div class="publi">
                {{ publication.title }}
              </div>
            </div>
            <div class="description">
              {{ publication.description }}
            </div>
            <div class="dates">
              <div class="created" *ngIf="publication.etat==1">
                Brouillon créé le {{ publication.created_at }}
              </div>
              <div class="created" *ngIf="publication.etat==2 && !publication.programmed">
                Publiée le {{ publication.published_formatted }}
              </div>
              <div class="created" *ngIf="publication.etat==3 && !publication.programmed">
                Publiée le {{ publication.published_formatted }} pour les <span style="font-weight: 500;">membres</span>
              </div>
              <div class="maj" *ngIf="publication.etat==1">
                Édité le {{ publication.updated_at }}
              </div>
              <div class="maj" *ngIf="publication.etat==2 || publication.etat==3 && !publication.programmed">
                Éditée le {{ publication.updated_at }}
              </div>
              <div class="programmed" *ngIf="publication.etat==2 && publication.programmed">
                Programmée pour le {{ publication.published_formatted }}
              </div>
            </div>
            <div class="actions">
              <div class="edit" (click)="onEdit(publication.id)">
                <svg height="401pt" viewBox="0 -1 401.52289 401" width="401pt" xmlns="http://www.w3.org/2000/svg"><path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"/></svg>
                <span>Éditer</span>
              </div>
              <div *ngIf="publication.etat == 1 && !publication.programmed" class="publish" (click)="onPublish(publication.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 59.2 59.2" style="enable-background:new 0 0 59.2 59.2;" xml:space="preserve">
                  <g>
                    <path d="M51.062,21.561c-11.889-11.889-31.232-11.889-43.121,0L0,29.501l8.138,8.138c5.944,5.944,13.752,8.917,21.561,8.917
                      s15.616-2.972,21.561-8.917l7.941-7.941L51.062,21.561z M49.845,36.225c-11.109,11.108-29.184,11.108-40.293,0l-6.724-6.724
                      l6.527-6.527c11.109-11.108,29.184-11.108,40.293,0l6.724,6.724L49.845,36.225z"/>
                    <path d="M28.572,21.57c-3.86,0-7,3.14-7,7c0,0.552,0.448,1,1,1s1-0.448,1-1c0-2.757,2.243-5,5-5c0.552,0,1-0.448,1-1
                      S29.125,21.57,28.572,21.57z"/>
                    <path d="M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,40.57
                      c-6.065,0-11-4.935-11-11s4.935-11,11-11s11,4.935,11,11S35.638,40.57,29.572,40.57z"/>
                  </g>
                </svg>
                <span>Publier</span>
              </div>
              <div *ngIf="publication.etat == 3 && !publication.programmed" class="publish" (click)="onPublish(publication.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 59.2 59.2" style="enable-background:new 0 0 59.2 59.2;" xml:space="preserve">
                  <g>
                    <path d="M51.062,21.561c-11.889-11.889-31.232-11.889-43.121,0L0,29.501l8.138,8.138c5.944,5.944,13.752,8.917,21.561,8.917
                      s15.616-2.972,21.561-8.917l7.941-7.941L51.062,21.561z M49.845,36.225c-11.109,11.108-29.184,11.108-40.293,0l-6.724-6.724
                      l6.527-6.527c11.109-11.108,29.184-11.108,40.293,0l6.724,6.724L49.845,36.225z"/>
                    <path d="M28.572,21.57c-3.86,0-7,3.14-7,7c0,0.552,0.448,1,1,1s1-0.448,1-1c0-2.757,2.243-5,5-5c0.552,0,1-0.448,1-1
                      S29.125,21.57,28.572,21.57z"/>
                    <path d="M29.572,16.57c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S36.741,16.57,29.572,16.57z M29.572,40.57
                      c-6.065,0-11-4.935-11-11s4.935-11,11-11s11,4.935,11,11S35.638,40.57,29.572,40.57z"/>
                  </g>
                </svg>
                <span>Publier pour tous les utilisateurs</span>
              </div>
              <div *ngIf="publication.etat !== 3 && !publication.programmed" class="draft" (click)="onPublishForAccounts(publication.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M52.179,40.5l-5.596,8.04l-3.949-3.241c-0.426-0.352-1.057-0.287-1.407,0.138c-0.351,0.427-0.289,1.058,0.139,1.407
                      l4.786,3.929C46.331,50.921,46.556,51,46.786,51c0.045,0,0.091-0.003,0.137-0.01c0.276-0.038,0.524-0.19,0.684-0.419l6.214-8.929
                      c0.315-0.453,0.204-1.076-0.25-1.392C53.117,39.933,52.495,40.046,52.179,40.5z"/>
                    <path d="M54.164,35.163C54.709,32.978,55,30.742,55,28.5C55,13.337,42.664,1,27.5,1S0,13.337,0,28.5
                      c0,8.01,3.444,15.229,8.927,20.259l-0.026,0.023l0.891,0.751c0.056,0.047,0.117,0.086,0.173,0.133
                      c0.477,0.396,0.972,0.772,1.476,1.136c0.159,0.115,0.318,0.23,0.479,0.341c0.535,0.369,1.085,0.719,1.646,1.051
                      c0.122,0.071,0.244,0.141,0.366,0.211c0.613,0.349,1.239,0.678,1.881,0.981c0.047,0.022,0.094,0.042,0.141,0.064
                      c2.089,0.971,4.319,1.684,6.65,2.105c0.062,0.011,0.123,0.022,0.185,0.033c0.723,0.125,1.455,0.225,2.197,0.292
                      c0.09,0.008,0.181,0.013,0.271,0.021C25.998,55.961,26.744,56,27.5,56c3.262,0,6.454-0.577,9.503-1.702
                      C39.389,57.168,42.984,59,47,59c7.168,0,13-5.832,13-13C60,41.478,57.677,37.492,54.164,35.163z M2,28.5C2,14.439,13.439,3,27.5,3
                      S53,14.439,53,28.5c0,1.903-0.214,3.804-0.639,5.666c-0.017-0.008-0.036-0.013-0.053-0.021c-0.376-0.169-0.762-0.32-1.156-0.453
                      c-0.034-0.011-0.067-0.026-0.101-0.037c-0.411-0.135-0.83-0.251-1.258-0.345c-0.02-0.005-0.04-0.011-0.06-0.016
                      c-0.417-0.09-0.841-0.158-1.271-0.207c-0.03-0.004-0.06-0.01-0.09-0.014C47.921,33.027,47.464,33,47,33
                      c-5.923,0-10.923,3.986-12.485,9.413C34.077,42,33.818,41.425,33.818,40.8v-2.957c0.198-0.243,0.405-0.518,0.617-0.817
                      c1.096-1.547,1.975-3.269,2.616-5.123c1.266-0.602,2.085-1.864,2.085-3.289v-3.545c0-0.866-0.318-1.708-0.886-2.369v-4.667
                      c0.052-0.52,0.236-3.448-1.883-5.864C34.524,10.065,31.541,9,27.5,9s-7.024,1.065-8.867,3.168
                      c-2.119,2.416-1.935,5.346-1.883,5.864v4.667c-0.568,0.661-0.886,1.503-0.886,2.369v3.545c0,1.101,0.494,2.128,1.339,2.821
                      c0.81,3.173,2.476,5.575,3.092,6.389v2.894c0,0.816-0.445,1.566-1.162,1.958l-7.907,4.313c-0.253,0.138-0.502,0.298-0.752,0.477
                      C5.276,42.792,2,36.022,2,28.5z M25.605,53.922c-0.109-0.008-0.218-0.015-0.326-0.025c-0.634-0.056-1.266-0.131-1.893-0.234
                      c-0.026-0.004-0.052-0.01-0.077-0.014c-1.327-0.222-2.632-0.548-3.903-0.974c-0.034-0.011-0.068-0.023-0.102-0.035
                      c-1.237-0.42-2.44-0.939-3.601-1.544c-0.067-0.035-0.135-0.068-0.201-0.103c-0.515-0.275-1.019-0.573-1.515-0.883
                      c-0.143-0.09-0.284-0.181-0.425-0.273c-0.456-0.298-0.905-0.608-1.343-0.936c-0.045-0.034-0.088-0.07-0.133-0.104
                      c0.032-0.018,0.064-0.036,0.097-0.054l7.907-4.313c1.359-0.742,2.204-2.165,2.204-3.714v-3.603l-0.233-0.278
                      c-0.021-0.025-2.176-2.634-2.999-6.215l-0.091-0.396l-0.341-0.221c-0.481-0.311-0.769-0.831-0.769-1.392v-3.545
                      c0-0.465,0.198-0.898,0.557-1.223l0.33-0.298v-5.57l-0.009-0.131c-0.003-0.024-0.298-2.429,1.396-4.36
                      C21.583,11.837,24.06,11,27.5,11c3.425,0,5.897,0.83,7.346,2.466c1.692,1.911,1.415,4.361,1.413,4.381l-0.009,5.701l0.33,0.298
                      c0.359,0.324,0.557,0.758,0.557,1.223v3.545c0,0.724-0.475,1.356-1.181,1.574l-0.498,0.154l-0.16,0.496
                      c-0.589,1.833-1.429,3.525-2.496,5.032c-0.259,0.367-0.514,0.695-0.736,0.948l-0.248,0.283V40.8c0,1.587,0.868,3.015,2.268,3.746
                      C34.033,45.024,34,45.508,34,46c0,0.292,0.01,0.583,0.029,0.873c0.007,0.103,0.021,0.205,0.031,0.307
                      c0.009,0.096,0.018,0.191,0.029,0.287c0.01,0.09,0.015,0.181,0.027,0.27c0.023,0.17,0.056,0.338,0.086,0.507
                      c0.02,0.115,0.035,0.231,0.058,0.345c0,0,0.002,0.008,0.003,0.012c0.006,0.03,0.015,0.058,0.021,0.088
                      c0.031,0.146,0.068,0.291,0.104,0.436c0.025,0.101,0.045,0.202,0.072,0.302c0.024,0.088,0.055,0.173,0.081,0.26
                      c0.034,0.116,0.07,0.231,0.108,0.345c0.024,0.072,0.042,0.145,0.067,0.216c0.07,0.201,0.15,0.399,0.23,0.596
                      c0.001,0.003,0.002,0.005,0.003,0.008c0.061,0.15,0.125,0.297,0.191,0.444c0.049,0.109,0.1,0.218,0.152,0.326
                      c0.034,0.071,0.064,0.143,0.099,0.213c0.023,0.046,0.05,0.09,0.074,0.136c0.084,0.163,0.173,0.322,0.264,0.48
                      c0.027,0.047,0.051,0.096,0.078,0.143C33.119,53.527,30.33,54,27.5,54C26.866,54,26.234,53.969,25.605,53.922z M47,57
                      c-3.661,0-6.901-1.805-8.902-4.564c-0.083-0.114-0.158-0.231-0.236-0.347c-0.054-0.08-0.111-0.158-0.162-0.239
                      c-0.043-0.069-0.085-0.138-0.127-0.208c-0.045-0.074-0.085-0.15-0.128-0.225c-0.069-0.122-0.143-0.241-0.207-0.365
                      c-0.047-0.091-0.089-0.185-0.134-0.278c-0.012-0.025-0.023-0.051-0.035-0.076c-0.075-0.157-0.153-0.312-0.221-0.472
                      c-0.036-0.085-0.063-0.173-0.097-0.258c-0.077-0.199-0.155-0.398-0.221-0.602c-0.031-0.095-0.055-0.193-0.083-0.289
                      c-0.009-0.03-0.017-0.059-0.025-0.088c-0.056-0.199-0.11-0.399-0.155-0.603c-0.014-0.063-0.025-0.127-0.038-0.191
                      c-0.012-0.06-0.025-0.121-0.036-0.181c-0.041-0.222-0.075-0.446-0.103-0.672c-0.003-0.026-0.006-0.052-0.009-0.079
                      c-0.009-0.082-0.022-0.164-0.029-0.246C36.021,46.681,36,46.343,36,46c0-0.315,0.021-0.626,0.047-0.934
                      c0.028-0.295,0.067-0.599,0.122-0.919l0.074-0.433C37.298,38.742,41.719,35,47,35c0.446,0,0.89,0.034,1.332,0.089
                      c0.101,0.012,0.199,0.031,0.299,0.046c0.365,0.055,0.728,0.127,1.086,0.219c0.075,0.019,0.15,0.037,0.225,0.058
                      c0.882,0.247,1.735,0.601,2.537,1.063C55.773,38.377,58,41.93,58,46C58,52.065,53.065,57,47,57z"/>
                  </g>
                </svg>
                <span>Restreindre aux membres</span>
              </div>
              <div *ngIf="publication.etat !== 1 && !publication.programmed" class="draft" (click)="onDraft(publication.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 59.049 59.049" style="enable-background:new 0 0 59.049 59.049;" xml:space="preserve">
                  <g>
                    <path d="M11.285,41.39c0.184,0.146,0.404,0.218,0.623,0.218c0.294,0,0.585-0.129,0.783-0.377c0.344-0.432,0.273-1.061-0.159-1.405
                      c-0.801-0.638-1.577-1.331-2.305-2.06l-7.398-7.398l7.629-7.629c7.334-7.333,18.003-9.836,27.839-6.534
                      c0.523,0.173,1.09-0.107,1.267-0.63c0.175-0.523-0.106-1.091-0.63-1.267c-10.562-3.545-22.016-0.857-29.89,7.016L0,30.368
                      l8.812,8.812C9.593,39.962,10.426,40.705,11.285,41.39z"/>
                    <path d="M50.237,21.325c-1.348-1.348-2.826-2.564-4.394-3.616c-0.458-0.307-1.081-0.185-1.388,0.273
                      c-0.308,0.458-0.185,1.08,0.273,1.388c1.46,0.979,2.838,2.113,4.094,3.369l7.398,7.398l-7.629,7.629
                      c-7.385,7.385-18.513,9.882-28.352,6.356c-0.52-0.187-1.093,0.084-1.279,0.604c-0.186,0.52,0.084,1.092,0.604,1.279
                      c3.182,1.14,6.49,1.693,9.776,1.693c7.621,0,15.124-2.977,20.665-8.518l9.043-9.043L50.237,21.325z"/>
                    <path d="M30.539,41.774c-2.153,0-4.251-0.598-6.07-1.73c-0.467-0.29-1.084-0.148-1.377,0.321c-0.292,0.469-0.148,1.085,0.321,1.377
                      c2.135,1.33,4.6,2.032,7.126,2.032c7.444,0,13.5-6.056,13.5-13.5c0-2.685-0.787-5.279-2.275-7.502
                      c-0.308-0.459-0.93-0.582-1.387-0.275c-0.459,0.308-0.582,0.929-0.275,1.387c1.267,1.893,1.937,4.102,1.937,6.39
                      C42.039,36.616,36.88,41.774,30.539,41.774z"/>
                    <path d="M30.539,18.774c2.065,0,4.089,0.553,5.855,1.6c0.474,0.281,1.088,0.125,1.37-0.351c0.281-0.475,0.125-1.088-0.351-1.37
                      c-2.074-1.229-4.451-1.879-6.875-1.879c-7.444,0-13.5,6.056-13.5,13.5c0,2.084,0.462,4.083,1.374,5.941
                      c0.174,0.354,0.529,0.56,0.899,0.56c0.147,0,0.298-0.033,0.439-0.102c0.496-0.244,0.701-0.843,0.458-1.338
                      c-0.776-1.582-1.17-3.284-1.17-5.06C19.039,23.933,24.198,18.774,30.539,18.774z"/>
                    <path d="M54.621,5.567c-0.391-0.391-1.023-0.391-1.414,0l-46.5,46.5c-0.391,0.391-0.391,1.023,0,1.414
                      c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l46.5-46.5C55.012,6.591,55.012,5.958,54.621,5.567z"/>
                  </g>
                </svg>
                <span>Dépublier</span>
              </div>
              <div class="delete" (click)="onDelete(publication.id)">
                <svg height="427pt" viewBox="-40 0 427 427.00131" width="427pt" xmlns="http://www.w3.org/2000/svg"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/></svg>
                <span>Supprimer</span>
              </div>
              <div class="stats" (click)="onViewStats(publication.id)">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M440,0H40C17.944,0,0,17.944,0,40v248v32c0,22.056,17.944,40,40,40h134.416l-13.088,72h-31.432
                        c-9.152,0-17.376,5.08-21.472,13.264c-3.744,7.496-3.352,16.224,1.048,23.352c4.4,7.128,12.04,11.384,20.416,11.384h220.216
                        c8.376,0,16.008-4.256,20.416-11.384s4.8-15.856,1.048-23.352C367.488,437.08,359.264,432,350.112,432H318.68l-13.088-72H440
                        c22.056,0,40-17.944,40-40v-32V40C480,17.944,462.056,0,440,0z M312,448h38.112c3.048,0,5.792,1.696,7.16,4.424
                        c1.8,3.6,0.352,6.64-0.344,7.784c-0.72,1.136-2.792,3.792-6.816,3.792H129.888c-4.024,0-6.104-2.656-6.808-3.8
                        c-0.704-1.144-2.152-4.176-0.344-7.784c1.36-2.72,4.104-4.416,7.152-4.416H168h64c4.416,0,8-3.584,8-8c0-4.416-3.584-8-8-8
                        h-54.416l13.088-72h98.648l13.096,72H280c-4.416,0-8,3.584-8,8c0,4.416,3.584,8,8,8H312z M464,320c0,13.232-10.768,24-24,24H296
                        H184H40c-13.232,0-24-10.768-24-24v-24h448V320z M464,280H16V40c0-13.232,10.768-24,24-24h400c13.232,0,24,10.768,24,24V280z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M216,160h-80V80c0-4.416-3.584-8-8-8c-52.936,0-96,43.064-96,96c0,52.936,43.064,96,96,96c52.936,0,96-43.064,96-96
                        C224,163.584,220.416,160,216,160z M128,248c-44.112,0-80-35.888-80-80c0-41.408,31.632-75.576,72-79.6V168c0,4.416,3.584,8,8,8
                        h79.6C203.576,216.368,169.408,248,128,248z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M160,40c-4.416,0-8,3.584-8,8v88c0,4.416,3.584,8,8,8h88c4.416,0,8-3.584,8-8C256,83.064,212.936,40,160,40z M168,128
                        V56.4c37.736,3.76,67.84,33.864,71.6,71.6H168z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M440,32H280c-4.416,0-8,3.584-8,8v216c0,4.416,3.584,8,8,8h160c4.416,0,8-3.584,8-8V40C448,35.584,444.416,32,440,32z
                        M432,248H288V48h144V248z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M360,72h-48c-4.416,0-8,3.584-8,8s3.584,8,8,8h48c4.416,0,8-3.584,8-8S364.416,72,360,72z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M408,104h-96c-4.416,0-8,3.584-8,8s3.584,8,8,8h96c4.416,0,8-3.584,8-8S412.416,104,408,104z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M360,136h-48c-4.416,0-8,3.584-8,8s3.584,8,8,8h48c4.416,0,8-3.584,8-8S364.416,136,360,136z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M408,168h-96c-4.416,0-8,3.584-8,8s3.584,8,8,8h96c4.416,0,8-3.584,8-8S412.416,168,408,168z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M360,200h-48c-4.416,0-8,3.584-8,8s3.584,8,8,8h48c4.416,0,8-3.584,8-8S364.416,200,360,200z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M256,312h-32c-4.416,0-8,3.584-8,8c0,4.416,3.584,8,8,8h32c4.416,0,8-3.584,8-8C264,315.584,260.416,312,256,312z"/>
                    </g>
                  </g>
                </svg>
                <span>Statistiques</span>
              </div>
            </div>
          </div>
          <div *ngIf="publication.audio && !publication.video && !publication.url" class="infos">
            <div class="audios">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 232.914 232.914" style="enable-background:new 0 0 232.914 232.914;" xml:space="preserve">
                  <g>
                    <path d="M9,64.099c-4.971,0-9,4.029-9,9v86.716c0,4.971,4.029,9,9,9s9-4.029,9-9V73.099C18,68.128,13.971,64.099,9,64.099z"/>
                    <path d="M51.983,38.092c-4.971,0-9,4.029-9,9v138.73c0,4.971,4.029,9,9,9s9-4.029,9-9V47.092
                      C60.983,42.121,56.954,38.092,51.983,38.092z"/>
                    <path d="M94.966,1.896c-4.971,0-9,4.029-9,9v211.121c0,4.971,4.029,9,9,9s9-4.029,9-9V10.896
                      C103.966,5.926,99.937,1.896,94.966,1.896z"/>
                    <path d="M137.948,54.361c-4.971,0-9,4.029-9,9v106.193c0,4.971,4.029,9,9,9s9-4.029,9-9V63.361
                      C146.948,58.39,142.919,54.361,137.948,54.361z"/>
                    <path d="M180.931,64.099c-4.971,0-9,4.029-9,9v86.716c0,4.971,4.029,9,9,9s9-4.029,9-9V73.099
                      C189.931,68.128,185.901,64.099,180.931,64.099z"/>
                    <path d="M223.914,92.919c-4.971,0-9,4.029-9,9v29.077c0,4.971,4.029,9,9,9s9-4.029,9-9v-29.077
                      C232.914,96.948,228.885,92.919,223.914,92.919z"/>
                  </g>
                </svg>
              </div>
              <div class="text">
                {{ publication.mediaCount - 1 }} son<span *ngIf="publication.mediaCount > 2">s</span> 
              </div>
            </div>
            <div class="points">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path style="fill:#FD003A;" d="M256,0C156.698,0,76,80.7,76,180c0,33.6,9.302,66.301,27.001,94.501l140.797,230.414
                    c2.402,3.9,6.002,6.301,10.203,6.901c5.698,0.899,12.001-1.5,15.3-7.2l141.2-232.516C427.299,244.501,436,212.401,436,180
                    C436,80.7,355.302,0,256,0z M256,270c-50.398,0-90-40.8-90-90c0-49.501,40.499-90,90-90s90,40.499,90,90
                    C346,228.9,306.999,270,256,270z"/>
                  <path style="fill:#E50027;" d="M256,0v90c49.501,0,90,40.499,90,90c0,48.9-39.001,90-90,90v241.991
                    c5.119,0.119,10.383-2.335,13.3-7.375L410.5,272.1c16.799-27.599,25.5-59.699,25.5-92.1C436,80.7,355.302,0,256,0z"/>
                </svg>
              </div>
              <div class="text">
                {{ publication.activePoints }} point<span *ngIf="publication.activePoints > 1">s</span>
              </div>
            </div>
          </div>
          <div *ngIf="publication.video && !publication.audio && !publication.url" class="infos">
            <div class="videos">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M462.002,92.002h-42.001V50c0-27.57-22.43-50-50-50h-320c-27.57,0-50,22.43-50,50v320c0,27.57,22.43,50,50,50h42.001
                        v42.002c0,27.57,22.43,50,50,50h320c27.57,0,50-22.43,50-50V142.001C512.003,114.431,489.573,92.002,462.002,92.002z M50.001,400
                        c-16.542,0-30-13.457-30-30V50c0-16.542,13.458-30,30-30h320c16.542,0,30,13.458,30,30v320c0,16.542-13.458,30-30,30H50.001z
                        M492.002,462.002c0,16.542-13.458,30-30,30h-320c-16.542,0-30-13.458-30-30V420h257.999c27.57,0,50-22.43,50-50V112.002h42.001
                        c16.542,0,30,13.458,30,30V462.002z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M142.25,457.002h-0.27c-5.523,0-10,4.477-10,10s4.477,10,10,10h0.27c5.523,0,10-4.477,10-10
                        S147.774,457.002,142.25,457.002z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M462.025,457.002H170.98c-5.523,0-10,4.477-10,10s4.477,10,10,10h291.044c5.522,0,10-4.477,10-10
                        S467.547,457.002,462.025,457.002z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M110.035,35h-0.27c-5.523,0-10,4.477-10,10s4.477,10,10,10h0.27c5.523,0,10-4.477,10-10S115.559,35,110.035,35z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M81.036,35H50.001c-5.523,0-10,4.477-10,10s4.477,10,10,10h31.034c5.523,0,10-4.477,10-10
                        C91.036,39.477,86.559,35,81.036,35z"/>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M293.485,201.129c-2.377-8.799-8.037-16.146-15.922-20.676l-48.918-28.242c-0.491-0.319-0.986-0.625-1.484-0.911
                        l-48.792-28.172c-5.589-3.685-12.086-5.631-18.813-5.631c-18.871,0-34.224,15.352-34.224,34.224v116.611
                        c0,0.407,0.025,0.809,0.072,1.203c0.197,5.58,1.772,11.06,4.586,15.92c6.09,10.516,17.428,17.048,29.59,17.048
                        c5.986,0,11.9-1.592,17.089-4.598l50.492-29.153c0.286-0.166,0.561-0.343,0.825-0.532l49.219-28.414
                        c5.373-3.001,9.84-7.417,12.923-12.779C294.669,219.125,295.861,209.927,293.485,201.129z M272.79,217.061
                        c-1.284,2.233-3.141,4.067-5.369,5.304c-0.049,0.027-0.099,0.055-0.148,0.083L217.14,251.39c-0.287,0.165-0.562,0.343-0.826,0.532
                        l-49.656,28.67c-2.159,1.25-4.607,1.912-7.078,1.912c-5.05,0-9.757-2.709-12.283-7.071c-1.253-2.164-1.914-4.616-1.912-7.091
                        c0-0.34-0.017-0.679-0.052-1.015V151.721h0.001c0-7.843,6.381-14.224,14.224-14.224c2.851,0,5.595,0.836,7.936,2.417
                        c0.195,0.131,0.394,0.256,0.597,0.374l49.091,28.344c0.246,0.142,0.482,0.292,0.716,0.447c0.166,0.109,0.335,0.214,0.507,0.314
                        l49.177,28.391c3.27,1.879,5.612,4.919,6.596,8.561C275.161,209.987,274.668,213.793,272.79,217.061z"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="text">
                {{ publication.video_codes.length }} vidéo<span *ngIf="publication.video_codes.length > 1">s</span>
              </div>
            </div>
            <div class="points">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path style="fill:#FD003A;" d="M256,0C156.698,0,76,80.7,76,180c0,33.6,9.302,66.301,27.001,94.501l140.797,230.414
                    c2.402,3.9,6.002,6.301,10.203,6.901c5.698,0.899,12.001-1.5,15.3-7.2l141.2-232.516C427.299,244.501,436,212.401,436,180
                    C436,80.7,355.302,0,256,0z M256,270c-50.398,0-90-40.8-90-90c0-49.501,40.499-90,90-90s90,40.499,90,90
                    C346,228.9,306.999,270,256,270z"/>
                  <path style="fill:#E50027;" d="M256,0v90c49.501,0,90,40.499,90,90c0,48.9-39.001,90-90,90v241.991
                    c5.119,0.119,10.383-2.335,13.3-7.375L410.5,272.1c16.799-27.599,25.5-59.699,25.5-92.1C436,80.7,355.302,0,256,0z"/>
                </svg>
              </div>
              <div class="text">
                {{ publication.activePoints }} point<span *ngIf="publication.activePoints > 1">s</span>
              </div>
            </div>
          </div>
          <div *ngIf="publication.url && !publication.video && !publication.audio" class="infos">
            <div class="points">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path style="fill:#FD003A;" d="M256,0C156.698,0,76,80.7,76,180c0,33.6,9.302,66.301,27.001,94.501l140.797,230.414
                    c2.402,3.9,6.002,6.301,10.203,6.901c5.698,0.899,12.001-1.5,15.3-7.2l141.2-232.516C427.299,244.501,436,212.401,436,180
                    C436,80.7,355.302,0,256,0z M256,270c-50.398,0-90-40.8-90-90c0-49.501,40.499-90,90-90s90,40.499,90,90
                    C346,228.9,306.999,270,256,270z"/>
                  <path style="fill:#E50027;" d="M256,0v90c49.501,0,90,40.499,90,90c0,48.9-39.001,90-90,90v241.991
                    c5.119,0.119,10.383-2.335,13.3-7.375L410.5,272.1c16.799-27.599,25.5-59.699,25.5-92.1C436,80.7,355.302,0,256,0z"/>
                </svg>
              </div>
              <div class="text">
                {{ publication.activePoints }} point<span *ngIf="publication.activePoints > 1">s</span>
              </div>
            </div>
          </div>
          <div *ngIf="!publication.url && !publication.video && !publication.audio" class="infos">
            <div class="photos">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <g>
                        <path d="M480,96H128c-17.673,0-32,14.327-32,32v352c0,17.673,14.327,32,32,32h352c17.673,0,32-14.327,32-32V128
                          C512,110.327,497.673,96,480,96z M480,480H128V128h352V480z"/>
                        <path d="M32,32h352v32h32V32c0-17.673-14.327-32-32-32H32C14.327,0,0,14.327,0,32v352c0,17.673,14.327,32,32,32h32v-32H32V32z"/>
                        <path d="M176,400h256c8.836,0.051,16.041-7.07,16.093-15.907c0.025-4.299-1.681-8.426-4.733-11.453l-64-64
                          c-6.241-6.204-16.319-6.204-22.56,0l-20.8,20.8l-52.64-52.64c-6.241-6.204-16.319-6.204-22.56,0l-96,96
                          c-6.186,6.31-6.087,16.44,0.223,22.626C167.959,398.304,171.889,399.942,176,400z"/>
                        <circle cx="384" cy="240" r="32"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="text">
                {{ publication.mediaCount }} photo<span *ngIf="publication.mediaCount > 1">s</span>
              </div>
            </div>
            <div class="points">
              <div class="icone">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path style="fill:#FD003A;" d="M256,0C156.698,0,76,80.7,76,180c0,33.6,9.302,66.301,27.001,94.501l140.797,230.414
                    c2.402,3.9,6.002,6.301,10.203,6.901c5.698,0.899,12.001-1.5,15.3-7.2l141.2-232.516C427.299,244.501,436,212.401,436,180
                    C436,80.7,355.302,0,256,0z M256,270c-50.398,0-90-40.8-90-90c0-49.501,40.499-90,90-90s90,40.499,90,90
                    C346,228.9,306.999,270,256,270z"/>
                  <path style="fill:#E50027;" d="M256,0v90c49.501,0,90,40.499,90,90c0,48.9-39.001,90-90,90v241.991
                    c5.119,0.119,10.383-2.335,13.3-7.375L410.5,272.1c16.799-27.599,25.5-59.699,25.5-92.1C436,80.7,355.302,0,256,0z"/>
                </svg>
              </div>
              <div class="text">
                {{ publication.activePoints }} point<span *ngIf="publication.activePoints > 1">s</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <pagination-controls
        (pageChange)="onPageChange($event)"
        directionLinks="false"
      ></pagination-controls>
    </div>
  </div>
</div>
