import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isAuthenticated = false;
  menuIsActive = true;
  canHide = false;
  hasLangs: boolean = false;
  langs: any = [];
  currentLang: any;

  private userSub: Subscription;

  menuMobile: boolean = false;
  editing: boolean = false;

  constructor(private router: Router, private http: HttpClient, private authService: AuthService, private apiService: ApiService, private toastr: ToastrService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (
          val.url == "/create-circuit-1" ||
          val.url == "/create-circuit-2" ||
          val.url == "/create-circuit-3" ||
          val.url == "/create-circuit-4" ||
          val.url == "/create-circuit-5" ||
          val.url == "/create-publication-1" ||
          val.url == "/create-publication-2" ||
          val.url.startsWith('/edit-point/') ||
          val.url == "/add-point"
        ) {
          this.menuIsActive = false;
          this.canHide = true;
        } else {
          this.menuIsActive = true;
          this.canHide = false;
        }
        this.currentLang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
      }
    });
  }

  ngOnInit(): void {

    this.onResize();

    this.userSub = this.authService.user.subscribe(user => {
      this.isAuthenticated = !user ? false : true;
    });

    this.apiService.getLangs().subscribe(
      data => {
        this.hasLangs = true;
        this.langs = data.data;
        this.currentLang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
      },
      err => {
        console.log(err);
      }
    );
  }

  deployMenu() {
    this.menuIsActive = true;
  }

  hideMenu() {
    this.menuIsActive = false;
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  switchLang(langue) {
    if (this.router.url !== '/publications') {
      this.router.navigateByUrl('/').then(()=>{
        setTimeout(() => {
          localStorage.setItem('currentLang', JSON.stringify(langue.code_lang));
          this.currentLang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
          window.location.reload();
        }, 500);
      });
    } else {
      localStorage.setItem('currentLang', JSON.stringify(langue.code_lang));
      this.currentLang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';
      window.location.reload();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 900) {
      this.menuMobile = true;
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          if (val.url == "/create-publication-1") {
            this.editing = true;
          } else {
            this.editing = false;
          }
        }
      });
    } else {
      this.menuMobile = false;
    }
  }

  onLogout() {
    if(window.confirm('Êtes-vous sûr de vouloir vous déconneter ?')){
      this.authService.logout();
    }
  }

}
