import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  isLoading: boolean = false;

  users: any;
  usersFiltered: any;
  searchValue: string;

  editPass: boolean = false;
  editPassUser = null;
  
  constructor(private apiService: ApiService, private authService: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.isLoading = true;
    this.apiService.listAccounts().subscribe(
      data => {
        const entries = Object.values(data.data);
        this.users = entries;
        this.users.sort(function(a, b) { 
          return - ( a.scores_sum - b.scores_sum  ||  a.scores_sum.toString().localeCompare(b.scores_sum) );
        });
        this.usersFiltered = this.users;
        this.users.forEach(user => {
          user.sessions = user.sessions.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.trajet_id === value.trajet_id
            ))
          )
        });
        this.isLoading = false;        
      },
      err => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  onEdit(user) {
    this.editPass = true;
    this.editPassUser = user;
  }

  closeEditPass() {
    this.editPass = false;
    this.editPassUser = null;
  }

  onChangePass(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;
    this.editPass = false;
    const password = form.value.password;

    this.apiService.editAccount(this.editPassUser.email, password).subscribe(
      resData => {
        this.toastr.success('Mot de passe modifié', '', {
          timeOut: 2000,
        });
        this.isLoading = false;
      },
      errRes => {
        this.toastr.error('Une erreur est survenue');
        this.isLoading = false;
      }
    );

    form.reset();
  }

  onSearchChange(searchValue: string) {
    this.searchValue = searchValue;
    if (searchValue) {
      this.usersFiltered = this.users.filter(el => {
        if (el.lastname && el.firstname && el.email) {
          return el.lastname.toLowerCase().includes(searchValue.toLowerCase()) || el.firstname.toLowerCase().includes(searchValue.toLowerCase()) || el.email.toLowerCase().includes(searchValue.toLowerCase());
        } else if (el.lastname && el.email) {
          return el.lastname.toLowerCase().includes(searchValue.toLowerCase()) || el.email.toLowerCase().includes(searchValue.toLowerCase());
        } else if (el.firstname && el.email) {
          return el.firstname.toLowerCase().includes(searchValue.toLowerCase()) || el.email.toLowerCase().includes(searchValue.toLowerCase());
        } else {
          return el.email.toLowerCase().includes(searchValue.toLowerCase());
        }
      });
    } else {
      this.usersFiltered = this.users;
    }
  }

  exportUsers() {
    this.apiService.exportUsers().subscribe(
      data => {
        data = data.replace(/,/g, ';');
        var encodedUri = encodeURI('data:text/csv;charset=utf-8,' + data);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
      },
      err => {
        console.log(err);
      }
    );
  }

}
