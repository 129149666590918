<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Circuits quittés ({{exits ? numberOfItems : ''}})
    </div>
    <div class="search">
      <div class="searchbar">
        <input type="text" placeholder="Rechercher un circuit" (input)="onSearchChange($event.target.value)" />
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;"
          xml:space="preserve">
          <g>
            <g>
              <path
                d="M430.827,86.605c-49.161-51.53-124.712-59.44-180.6-28.393c-4.829,2.682-6.568,8.772-3.886,13.6
                              c2.682,4.83,8.772,6.567,13.6,3.886c51.118-28.401,115.436-18.236,156.371,24.671c42.236,44.811,46.992,111.607,14.317,161.128
                              c-45.008,68.362-142.217,78.664-200.5,20.37c-39.273-39.259-50.651-102.572-22.282-154.093c2.662-4.84,0.897-10.922-3.942-13.584
                              c-4.839-2.66-10.92-0.897-13.584,3.942c-0.613,1.115-1.703,3.193-2.754,5.324h0.001c-29.088,59.294-15.854,128.301,28.418,172.556
                              c67.148,67.162,179.31,55.53,231.345-23.507C484.648,215.946,480.07,138.85,430.827,86.605z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M456.438,55.562c-74.091-74.081-194.651-74.084-268.745,0.001c-66.628,66.638-73.801,171.115-19.542,245.856
                              l-31.167,31.16c-10.927-5.23-24.745-3.542-34.155,5.869L14.667,426.62c-19.547,19.534-19.563,51.168-0.005,70.713
                              c19.539,19.552,51.172,19.56,70.715,0.002l88.175-88.164c9.406-9.408,11.1-23.226,5.868-34.154l31.163-31.17
                              c74.758,54.271,179.227,47.079,245.855-19.54C530.522,250.214,530.522,129.656,456.438,55.562z M159.407,395.027L71.23,483.193
                              c-11.719,11.728-30.689,11.739-42.424-0.005c-11.73-11.721-11.737-30.691,0.002-42.422l88.164-88.174
                              c3.897-3.898,10.242-3.9,14.139,0l28.295,28.295C163.306,384.785,163.306,391.129,159.407,395.027z M166.479,359.67
                              l-14.148-14.148l28.481-28.474c2.234,2.479,4.529,4.909,6.88,7.259c2.351,2.351,4.78,4.647,7.261,6.882L166.479,359.67z
                               M442.293,310.163c-66.277,66.267-173.635,66.831-240.458,0c-66.743-66.736-66.346-174.099,0-240.456
                              c66.289-66.28,174.158-66.291,240.457,0C508.577,136.001,508.577,243.87,442.293,310.163z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M230.37,84.108c-0.07-0.09-0.15-0.17-0.24-0.26c-3.911-3.901-10.242-3.901-14.152,0c-3.901,3.911-3.901,10.242,0,14.152
                              c0.09,0.09,0.17,0.17,0.26,0.25c3.931,3.651,10.072,3.561,13.892-0.25C233.94,94.179,234.03,88.038,230.37,84.108z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <!-- <div class="filters">
        <div class="title">
          Filtrer par dates
        </div>
        <div class="date-time">
          <div class="start">
            <ngx-datepicker [(ngModel)]="datePickerStart" [options]="optionsDatePicker" (ngModelChange)="setStartDate($event)"></ngx-datepicker>
          </div>
          <div class="end">
            <ngx-datepicker [(ngModel)]="datePickerEnd" [options]="optionsDatePicker" (ngModelChange)="setEndDate($event)"></ngx-datepicker>
          </div>
        </div>
      </div> -->
  </div>
  <div class="contenu-page" *ngIf="exits">
    <div class="table-wrapper">
      <table>
        <tr>
          <th>ID Circuit</th>
          <th class="circuit">Nom du circuit</th>
          <th>Langue <br> du circuit</th>
          <th>La météo <br> a changé ?</th>
          <th>Le parcours <br> est trop long ?</th>
          <th>Le jeu est <br> trop difficile ?</th>
          <th>Autre commentaire</th>
          <th>Utilisateur</th>
          <th>Date</th>
        </tr>
        <tr *ngFor="let exit of filteredExits">
          <ng-container *ngIf="exit.id">
            <td class="id">{{exit.trajet.id}}</td>
            <td class="circuit">{{ exit.trajet.title }}</td>
            <td class="langue">
              <div class="flag fr" *ngIf="exit.trajet.lang == 'fr_FR'">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path style="fill:#41479B;" d="M170.667,423.721H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.1c0-4.875,3.953-8.828,8.828-8.828
                    h161.839V423.721z" />
                  <rect x="170.67" y="88.277" style="fill:#F5F5F5;" width="170.67" height="335.45" />
                  <path style="fill:#FF4B55;" d="M503.172,423.721H341.333V88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.793
                    C512,419.77,508.047,423.721,503.172,423.721z" />
                </svg>
              </div>
              <div class="flag en" *ngIf="exit.trajet.lang == 'en_GB'">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.002 512.002"
                  style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
                  <path style="fill:#41479B;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
                    h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.772,508.047,423.725,503.172,423.725z" />
                  <path style="fill:#F5F5F5;" d="M512,97.104c0-4.875-3.953-8.828-8.828-8.828h-39.495l-163.54,107.147V88.276h-88.276v107.147
                    L48.322,88.276H8.828C3.953,88.276,0,92.229,0,97.104v22.831l140.309,91.927H0v88.276h140.309L0,392.066v22.831
                    c0,4.875,3.953,8.828,8.828,8.828h39.495l163.54-107.147v107.147h88.276V316.578l163.54,107.147h39.495
                    c4.875,0,8.828-3.953,8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512,119.935V97.104z" />
                  <g>
                    <polygon style="fill:#FF4B55;" points="512,229.518 282.483,229.518 282.483,88.276 229.517,88.276 229.517,229.518 0,229.518 
                      0,282.483 229.517,282.483 229.517,423.725 282.483,423.725 282.483,282.483 512,282.483 	" />
                    <path style="fill:#FF4B55;" d="M178.948,300.138L0.25,416.135c0.625,4.263,4.14,7.59,8.577,7.59h12.159l190.39-123.586h-32.428
                      V300.138z" />
                    <path style="fill:#FF4B55;" d="M346.388,300.138H313.96l190.113,123.404c4.431-0.472,7.928-4.09,7.928-8.646v-7.258
                      L346.388,300.138z" />
                    <path style="fill:#FF4B55;"
                      d="M0,106.849l161.779,105.014h32.428L5.143,89.137C2.123,90.54,0,93.555,0,97.104V106.849z" />
                    <path style="fill:#FF4B55;" d="M332.566,211.863L511.693,95.586c-0.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138,211.863
                      H332.566z" />
                  </g>
                </svg>
              </div>
              <div class="flag de" *ngIf="exit.trajet.lang == 'de_DE'">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 5 3">
                  <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000" />
                  <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00" />
                  <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00" />
                </svg>
              </div>
            </td>
            <td class="bool" [class.true]="exit.reasons.meteoChanged">{{ exit.reasons.meteoChanged ? 'Oui' : 'Non' }}</td>
            <td class="bool" [class.true]="exit.reasons.routeTooLong">{{ exit.reasons.routeTooLong ? 'Oui' : 'Non' }}</td>
            <td class="bool" [class.true]="exit.reasons.gameTooDifficult">{{ exit.reasons.gameTooDifficult ? 'Oui' : 'Non'
              }}</td>
            <td class="commentaire">{{ exit.reasons.moreInfo }}</td>
            <td class="user">{{ exit.publicaccount.email }}</td>
            <td class="date">{{ exit.created_at | date: 'dd/MM/yyyy' }}</td>
          </ng-container>
          <ng-container *ngIf="!exit.id">
            <td class="id"></td>
            <td class="circuit"></td>
            <td class="langue">
            </td>
            <td class="bool" [class.true]="exit.reasons.meteoChanged"></td>
            <td class="bool" [class.true]="exit.reasons.routeTooLong"></td>
            <td class="bool" [class.true]="exit.reasons.gameTooDifficult"></td>
            <td class="commentaire"></td>
            <td class="user"></td>
            <td class="date"></td>
          </ng-container>
        </tr>
      </table>
    </div>
    <div class="pagination">
      <button (click)="previousPage()"><<</button>
      <div class="page-number"><span style="font-weight: 600;">{{ curPage }}</span>/{{numberOfPages}}</div>

      <button (click)="nextPage()">>></button>
    </div>
  </div>
</div>