import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '7';

  isLoading: boolean = false;
  checkAllPointsConfigured: boolean = false;

  pageTitle: string;
  title: string;
  description: string;
  files: File[] = [];
  youtube: string;
  routeId = JSON.parse(localStorage.getItem('currentRouteId'));
  currentRoute: any;
  currentRoutePoints: any = [];
  activePointsAndVariations: any = [];

  content: any;
  theme: any;
  time: any;
  distance: any;
  trace: any;

  cropImg: boolean = false;
  imgToCrop: any;
  croppingFile: any;

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.routeId) {
      this.apiService.getRoute(this.routeId).subscribe(
        data => {
          this.isLoading = false;
          this.currentRoute = data.data;
          this.pageTitle = 'Édition du circuit ' + this.currentRoute.title;
          this.currentRoutePoints = this.currentRoute.points;
          this.activePointsAndVariations = [...this.currentRoute.points, ...this.currentRoute.variations];
          this.title = this.currentRoute.title;
          this.description = this.currentRoute.description;
          this.youtube = this.currentRoute.youtube;
          this.content = this.currentRoute.content;
          this.theme = this.currentRoute.theme;
          this.time = this.currentRoute.time;
          this.distance = this.currentRoute.distance;
          this.trace = this.currentRoute.trace;
          localStorage.setItem('currentLang', JSON.stringify(this.currentRoute.lang));
          if (this.currentRoute.medias.length) {
            this.currentRoute.medias.forEach((element, index) => {
              var blob = null;
              var xhr = new XMLHttpRequest();
              xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
              xhr.responseType = "blob";
              xhr.onload = () => 
              {
                  blob = xhr.response;
                  let format = element.url.substring(element.url.lastIndexOf('.') + 1);
                  let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
                  var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
                  this.files.push(file);
              }
              xhr.send();
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.isLoading = false;
      this.pageTitle = 'Création d\'un circuit';
    }
  }

  async createRoute(title, toStep, description?, youtube?) {
    this.isLoading = true;
    let etat = '1';
    if (this.routeId) {
      etat = this.currentRoute.etat;
    }
    let filesResized = [];
    for await (const file of this.files) {
      await this.resizeImage(file, 1500, 1500).then(blob => {
        let resizedFile = new File([blob], file.name, {type: blob.type});
        filesResized.push(resizedFile);
      }, err => {
        console.error("Photo error", err);
      });
    };

    let blocking_geolocation = 0;
    if (this.currentRoute) blocking_geolocation = this.currentRoute.blocking_geolocation;
    let start_step_blocking_geolocation = 0;
    if (this.currentRoute) start_step_blocking_geolocation = this.currentRoute.start_step_blocking_geolocation;
    let end_step_blocking_geolocation = 0;
    if (this.currentRoute) end_step_blocking_geolocation = this.currentRoute.end_step_blocking_geolocation;

    this.apiService.addRoute(title, {route_id: this.routeId, description: description, youtube: youtube, trace: this.trace, theme: this.theme, time: this.time, distance: this.distance, content: this.content, medias: filesResized, etat: etat, blocking_geolocation: blocking_geolocation, start_step_blocking_geolocation: start_step_blocking_geolocation, end_step_blocking_geolocation: end_step_blocking_geolocation}).subscribe(
      data => {
        localStorage.setItem('currentRouteId', data.data.route_id);
        this.updateCurrentRoute(toStep);
      },
      err => {
        console.log(err);
      }
    );
  }

  updateCurrentRoute(toStep) {
    this.apiService.getRoutes().subscribe(
      data => {
        this.router.navigate(['/create-circuit-'+toStep]);
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  onSelect(event) {
    let files = event.addedFiles;
    files.forEach(file => {
      this.files.push(file);
    });
  }

  onCropImg(file) {
    this.imgToCrop = file;
    this.cropImg = true;
    this.croppingFile = file;
  }

  onCroppedImg(e) {
    if (e) {
      if (this.files.indexOf(this.croppingFile) !== -1) {
        this.files.splice(this.files.indexOf(this.croppingFile), 1);
        this.files.push(e);
      }
    }
    this.cropImg = false;
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  checkIfAllPointsConfigured() {
    this.checkAllPointsConfigured = true;
    this.currentRoutePoints.forEach((element, index) => {
      if (!element.title || !element.description) {
        this.checkAllPointsConfigured = false;
      }
    });
  }

  async resizeImage(file:File, maxWidth:number, maxHeight:number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
          let width = image.width;
          let height = image.height;
          
          if (width <= maxWidth && height <= maxHeight) {
              resolve(file);
          }

          let newWidth;
          let newHeight;

          if (width > height) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
          } else {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
          }

          let canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          let context = canvas.getContext('2d');

          context.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

}