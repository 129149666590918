<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title">
      Utilisateurs public
    </div>
    <div class="search">
      <div class="searchbar">
        <input
          type="text"
          placeholder="Rechercher"
          (input)="onSearchChange($event.target.value)"
          />
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
        	<g>
        		<g>
        			<path d="M430.827,86.605c-49.161-51.53-124.712-59.44-180.6-28.393c-4.829,2.682-6.568,8.772-3.886,13.6
        				c2.682,4.83,8.772,6.567,13.6,3.886c51.118-28.401,115.436-18.236,156.371,24.671c42.236,44.811,46.992,111.607,14.317,161.128
        				c-45.008,68.362-142.217,78.664-200.5,20.37c-39.273-39.259-50.651-102.572-22.282-154.093c2.662-4.84,0.897-10.922-3.942-13.584
        				c-4.839-2.66-10.92-0.897-13.584,3.942c-0.613,1.115-1.703,3.193-2.754,5.324h0.001c-29.088,59.294-15.854,128.301,28.418,172.556
        				c67.148,67.162,179.31,55.53,231.345-23.507C484.648,215.946,480.07,138.85,430.827,86.605z"/>
        		</g>
        	</g>
        	<g>
        		<g>
        			<path d="M456.438,55.562c-74.091-74.081-194.651-74.084-268.745,0.001c-66.628,66.638-73.801,171.115-19.542,245.856
        				l-31.167,31.16c-10.927-5.23-24.745-3.542-34.155,5.869L14.667,426.62c-19.547,19.534-19.563,51.168-0.005,70.713
        				c19.539,19.552,51.172,19.56,70.715,0.002l88.175-88.164c9.406-9.408,11.1-23.226,5.868-34.154l31.163-31.17
        				c74.758,54.271,179.227,47.079,245.855-19.54C530.522,250.214,530.522,129.656,456.438,55.562z M159.407,395.027L71.23,483.193
        				c-11.719,11.728-30.689,11.739-42.424-0.005c-11.73-11.721-11.737-30.691,0.002-42.422l88.164-88.174
        				c3.897-3.898,10.242-3.9,14.139,0l28.295,28.295C163.306,384.785,163.306,391.129,159.407,395.027z M166.479,359.67
        				l-14.148-14.148l28.481-28.474c2.234,2.479,4.529,4.909,6.88,7.259c2.351,2.351,4.78,4.647,7.261,6.882L166.479,359.67z
        				 M442.293,310.163c-66.277,66.267-173.635,66.831-240.458,0c-66.743-66.736-66.346-174.099,0-240.456
        				c66.289-66.28,174.158-66.291,240.457,0C508.577,136.001,508.577,243.87,442.293,310.163z"/>
        		</g>
        	</g>
        	<g>
        		<g>
        			<path d="M230.37,84.108c-0.07-0.09-0.15-0.17-0.24-0.26c-3.911-3.901-10.242-3.901-14.152,0c-3.901,3.911-3.901,10.242,0,14.152
        				c0.09,0.09,0.17,0.17,0.26,0.25c3.931,3.651,10.072,3.561,13.892-0.25C233.94,94.179,234.03,88.038,230.37,84.108z"/>
        		</g>
        	</g>
        </svg>
      </div>
    </div>
    <div class="total-users" *ngIf="users">
      {{ users.length }} utilisateurs public
      <div class="export" (click)="exportUsers()">
        Exporter
        <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path d="M4.359 5.956l-0.718-0.697 4.859-5.005 4.859 5.005-0.718 0.696-3.641-3.75v10.767h-1v-10.767l-3.641 3.751zM16 9.030v6.47c0 0.276-0.224 0.5-0.5 0.5h-14c-0.276 0-0.5-0.224-0.5-0.5v-6.475h-1v6.475c0 0.827 0.673 1.5 1.5 1.5h14c0.827 0 1.5-0.673 1.5-1.5v-6.47h-1z" fill="#000000" />
        </svg>
      </div>
    </div>
  </div>
  <div class="all-users" *ngIf="usersFiltered">
    <div class="user" *ngFor="let user of usersFiltered">
      <div class="content">
        <div class="names">
          <span class="name">
            {{ user.lastname }}
          </span>
          <span class="firstname">
            {{ user.firstname }}
          </span>
        </div>
        <div class="mail">
          {{ user.email }}
        </div>
        <div class="adresse">
          {{ user.adresse }}
        </div>
        <div class="city">
          {{ user.city }}
        </div>
        <div class="trajets">
          Parcours effectués : {{ user.sessions.length }}
        </div>
        <div class="points">
          Points : {{ user.scores_sum }}
        </div>
        <div class="keys">
          Clés : {{ user.keys_sum }}
        </div>
        <div class="letters">
          Lettres : {{ user.letters_sum }}
        </div>
      </div>
      <div class="edit" (click)="onEdit(user)">
        <svg _ngcontent-ana-c52="" height="401pt" viewBox="0 -1 401.52289 401" width="401pt" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-ana-c52="" d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0"></path><path _ngcontent-ana-c52="" d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0"></path></svg>
      </div>
    </div>
  </div>
  <div class="new-user" *ngIf="editPass">
    <div class="content">
      <div class="sur-head">
        <div class="close" (click)="closeEditPass()">
          <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
          </svg>
        </div>
      </div>
      <div class="title">
        Modifier le mot de passe pour :
        <br><br>
        {{ editPassUser.firstname }} {{ editPassUser.lastname }}
        <br>
        <span>{{ editPassUser.email }}</span>
      </div>
      <form #passForm="ngForm" (ngSubmit)='onChangePass(passForm)'>
        <div class="form-group Input">
          <label for="password" class="Input-label">Nouveau mot de passe</label>
          <input
            type="text"
            id="password"
            class="form-control Input-text"
            ngModel
            name="password"
            required
            placeholder="Nouveau mot de passe"
            />
        </div>
        <div class="form-validate">
          <button
            class="btn btn-primary"
            type="submit"
            disabled
            [disabled]="!passForm.valid"
            >
            Modifier le mot de passe
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
